import { createAuth0 } from "@auth0/auth0-vue";
export const domain = "login.iib-it.de";
export const clientId = "VshmO5s7HqAjfq7UqAW1W7nZSxr802qf";
export const audience = "https://admin-api.iib-it.de";
const client = createAuth0({
    domain,
    clientId,
    authorizationParams: {
        audience,
        redirect_uri: window.location.origin,
    },
});
export default client;
export const getAccessToken = async () => {
    const accessToken = await client.getAccessTokenSilently();
    return accessToken;
};
