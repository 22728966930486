import { createRouter, createWebHistory } from "vue-router";
import { createAuthGuard } from "@auth0/auth0-vue";
import DashboardLayout from "../components/dashboard/Layout.vue";
import MarketAreaLayout from "../components/marketarea/Layout.vue";
import PrintMapLayout from "@/components/printmap/Layout.vue";
import { app } from "@/plugins/app";
const routes = [
    {
        path: "/",
        name: "dashboard",
        component: DashboardLayout,
        beforeEnter: createAuthGuard(app),
    },
    {
        path: "/area",
        name: "create-area",
        component: MarketAreaLayout,
        beforeEnter: createAuthGuard(app),
    },
    {
        path: "/area/:id",
        name: "edit-area",
        component: MarketAreaLayout,
        beforeEnter: createAuthGuard(app),
    },
    {
        path: "/print-area/:id",
        name: "print-area",
        component: PrintMapLayout,
        beforeEnter: createAuthGuard(app),
    },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});
export default router;
