import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, unref as _unref } from "vue";
const _hoisted_1 = ["colspan"];
const _hoisted_2 = ["onMouseover", "onMouseleave"];
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { style: { "text-align": "center" } };
const _hoisted_5 = {
    key: 0,
    style: { "text-align": "end" }
};
const _hoisted_6 = {
    key: 1,
    style: { "text-align": "end" }
};
const _hoisted_7 = {
    key: 2,
    style: { "text-align": "end" }
};
const _hoisted_8 = {
    key: 3,
    style: { "text-align": "end" }
};
const _hoisted_9 = { style: { "text-align": "end" } };
const _hoisted_10 = { key: 0 };
const _hoisted_11 = { style: { "text-align": "end" } };
import { ref, computed } from "vue";
import { useConfirm } from "vuetify-use-dialog";
import { useStore } from "vuex";
import { AreaType } from "../../api/types";
import { formatNumber as formatterFormatNumber } from "../../plugins/formatter";
import { MarketAreaActions, MarketAreaGetters, MarketAreaState, } from "../../store/marketarea/types";
import { useRole } from "@/mixins/role";
import { useI18n } from "vue-i18n";
import EvaluationAmountRenderer from "../base/EvaluationAmountRenderer.vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'MarketList',
    setup(__props) {
        const store = useStore();
        const createConfirm = useConfirm();
        const groupInEdit = ref(null);
        const newGroupName = ref("");
        const selectedRows = ref([]);
        const { isAreaReadOnly } = useRole();
        const { t: $t } = useI18n();
        const initialMarkedArea = computed(() => store.state.marketArea[MarketAreaState.initialMarkedArea]);
        const marketAreaGroups = computed(() => store.getters[MarketAreaGetters.marketAreaGroups]);
        const marketAreaGroupById = (id) => store.getters[MarketAreaGetters.marketAreaGroupById](id);
        const areasWithGroup = computed(() => store.getters[MarketAreaGetters.areasWithGroup]);
        const showEvalAmount = computed(() => store.state.marketArea[MarketAreaState.showEvalAmount]);
        const showTraffic = computed(() => store.state.marketArea[MarketAreaState.showTraffic]);
        const activeArea = computed(() => store.state.marketArea[MarketAreaState.activeArea]);
        const toggleSelectedArea = (payload) => {
            store.dispatch(MarketAreaActions.toggleSelectedArea, payload);
        };
        const toggleRowSelection = (item) => {
            const idx = selectedRows.value.findIndex((area) => area.kgs === item.kgs);
            if (idx === -1) {
                selectedRows.value.push(item);
            }
            else {
                selectedRows.value.splice(idx, 1);
            }
        };
        const moveToGroup = (groupId) => {
            store.dispatch(MarketAreaActions.moveAreasToGroup, {
                groupId,
                areas: selectedRows.value,
            });
            selectedRows.value = [];
        };
        const saveGroupName = () => {
            if (groupInEdit.value !== null) {
                store.dispatch(MarketAreaActions.renameMarketAreaGroup, {
                    id: groupInEdit.value,
                    newName: newGroupName.value,
                });
                groupInEdit.value = null;
            }
        };
        const showEditGroupName = (group) => {
            groupInEdit.value = group;
            newGroupName.value = marketAreaGroupById(group)?.title;
        };
        const removeGroup = async (group) => {
            const res = await createConfirm({
                title: "Gruppe löschen?",
                content: "Alle ausgewählten Marktgebiete werden in die Standardgruppe verschoben",
                confirmationText: "OK",
                cancellationText: "Abbrechen",
            });
            if (res) {
                store.dispatch(MarketAreaActions.deleteMarketAreaGroup, group);
            }
        };
        const groupingFunction = (items, groupBy, groupDesc) => {
            return items.map((val) => {
                return {
                    items: [getSummedGroup(val), ...val.selectedAreas],
                    name: val.id,
                };
            });
        };
        const expanded = computed(() => {
            return groupingFunction(marketAreaGroups.value, ["parentKgs"], [false]);
        });
        const flattenGroupsWithGroupIds = (groups) => {
            const result = [];
            groups.forEach((group) => {
                group.items.forEach((item) => {
                    result.push({ ...item, groupId: group.name || 0 });
                });
            });
            return result;
        };
        const customGroupedItems = computed(() => {
            return flattenGroupsWithGroupIds(expanded.value);
        });
        const customItemClass = (item) => {
            if (!item.kgs) {
                return "summary-row";
            }
            return "";
        };
        const getSummedGroup = (item) => {
            const areaType = item.selectedAreas[0]?.areaType || "none";
            return {
                kgs: "",
                title: "Summe",
                geometry: {},
                parentKgs: "",
                areaType: areaType,
                licensePlate: "",
                district: "",
                centroid: {},
                population: sumSelectedAreaField(item.selectedAreas, "population"),
                offers: sumSelectedAreaField(item.selectedAreas, "offers"),
                offersAppartmentBuy: sumSelectedAreaField(item.selectedAreas, "offersAppartmentBuy"),
                offersAppartmentRent: sumSelectedAreaField(item.selectedAreas, "offersAppartmentRent"),
                offersHouseBuy: sumSelectedAreaField(item.selectedAreas, "offersHouseBuy"),
                offersHouseRent: sumSelectedAreaField(item.selectedAreas, "offersHouseRent"),
                hovered: false,
            };
        };
        const sumSelectedAreaField = (selectedAreas, field) => {
            return selectedAreas
                .map((area) => Number(area[field]) || 0)
                .reduce((a, b) => a + b, 0);
        };
        const headers = computed(() => {
            if (activeArea.value === AreaType.plz) {
                return [
                    { title: "Name", key: "title", sortable: false, align: "start" },
                    {},
                    {},
                    {},
                ];
            }
            const tableHeaders = [
                {
                    title: "Wählen",
                    key: "",
                    sortable: false,
                    width: "50px",
                    align: "start",
                },
                {
                    title: "Name",
                    key: "title",
                    sortable: false,
                    width: "100px",
                    align: "start",
                },
                { title: "Größe", key: "areaType", sortable: false, align: "center" },
                {
                    title: "Anz. WK",
                    key: "offersAppartmentBuy",
                    sortable: false,
                    align: "end",
                },
                {
                    title: "Anz. WM",
                    key: "offersAppartmentRent",
                    sortable: false,
                    align: "end",
                },
                {
                    title: "Anz. HK",
                    key: "offersHouseBuy",
                    sortable: false,
                    align: "end",
                },
                {
                    title: "Anz. HM",
                    key: "offersHouseRent",
                    sortable: false,
                    align: "end",
                },
                { title: "Einw.", key: "population", sortable: false, align: "end" },
                { title: "", key: "actions", sortable: false, align: "end" },
            ];
            return tableHeaders.filter((item) => {
                if (item.key.startsWith("offers")) {
                    return showEvalAmount.value;
                }
                if (isAreaReadOnly(initialMarkedArea.value) && item.key === "actions") {
                    return false;
                }
                return true;
            });
        });
        const formatNumber = (value, minimumFractionDigits = 0, maximumFractionDigits = 0) => {
            return formatterFormatNumber(value, minimumFractionDigits, maximumFractionDigits);
        };
        const highlightItem = (payload) => {
            store.dispatch(MarketAreaActions.toggleHoverArea, payload);
        };
        const createMarketAreaGroup = () => {
            store.dispatch(MarketAreaActions.createMarketAreaGroup);
        };
        return (_ctx, _cache) => {
            const _component_v_btn = _resolveComponent("v-btn");
            const _component_v_col = _resolveComponent("v-col");
            const _component_v_text_field = _resolveComponent("v-text-field");
            const _component_v_tooltip = _resolveComponent("v-tooltip");
            const _component_v_row = _resolveComponent("v-row");
            const _component_v_checkbox = _resolveComponent("v-checkbox");
            const _component_v_data_table = _resolveComponent("v-data-table");
            const _component_v_card_text = _resolveComponent("v-card-text");
            const _component_v_card = _resolveComponent("v-card");
            const _component_v_container = _resolveComponent("v-container");
            return (_openBlock(), _createBlock(_component_v_container, {
                fluid: "",
                class: "px-0 pt-1",
                "fill-height": "",
                style: { "align-items": "inherit" }
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_v_row, { align: "start" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_v_card, { elevation: "0" }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_v_card_text, { class: "px-1 py-1" }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_v_data_table, {
                                                        class: "market-list-table",
                                                        headers: headers.value,
                                                        items: customGroupedItems.value,
                                                        "disable-pagination": "",
                                                        "items-per-page": -1,
                                                        "hide-default-footer": "",
                                                        "group-by": [{ key: 'groupId', order: 'asc' }],
                                                        density: "compact"
                                                    }, {
                                                        headers: _withCtx(({ columns }) => [
                                                            _createElementVNode("tr", null, [
                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(columns.filter(({ key }) => key !== 'data-table-group'), (column) => {
                                                                    return (_openBlock(), _createElementBlock("th", {
                                                                        key: column.title
                                                                    }, _toDisplayString(column.title), 1));
                                                                }), 128))
                                                            ])
                                                        ]),
                                                        "group-header": _withCtx(({ item, columns, toggleGroup: toggle, isGroupOpen: isOpen, }) => [
                                                            _createElementVNode("tr", null, [
                                                                _createElementVNode("td", {
                                                                    colspan: columns.length,
                                                                    style: { "background-color": "rgba(0, 76, 76, 0.18)" }
                                                                }, [
                                                                    _createVNode(_component_v_row, { align: "center" }, {
                                                                        default: _withCtx(() => [
                                                                            _createVNode(_component_v_col, { cols: "1" }, {
                                                                                default: _withCtx(() => [
                                                                                    _createVNode(_component_v_btn, {
                                                                                        class: "submarket-table-expand-btn",
                                                                                        icon: "mdi-chevron-up",
                                                                                        onClick: () => toggle(item),
                                                                                        size: "small",
                                                                                        variant: "text",
                                                                                        style: _normalizeStyle(`transform: rotate(${isOpen(item) ? '0deg' : '180deg'})`)
                                                                                    }, null, 8, ["onClick", "style"])
                                                                                ], undefined, true),
                                                                                _: 2
                                                                            }, 1024),
                                                                            (groupInEdit.value === Number(item.value) && item)
                                                                                ? (_openBlock(), _createBlock(_component_v_col, {
                                                                                    key: 0,
                                                                                    cols: "auto"
                                                                                }, {
                                                                                    default: _withCtx(() => [
                                                                                        _createVNode(_component_v_text_field, {
                                                                                            density: "compact",
                                                                                            "hide-details": "",
                                                                                            width: "256px",
                                                                                            modelValue: newGroupName.value,
                                                                                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((newGroupName).value = $event))
                                                                                        }, null, 8, ["modelValue"])
                                                                                    ], undefined, true),
                                                                                    _: 1
                                                                                }))
                                                                                : (_openBlock(), _createBlock(_component_v_col, {
                                                                                    key: 1,
                                                                                    cols: "auto"
                                                                                }, {
                                                                                    default: _withCtx(() => [
                                                                                        _createTextVNode(_toDisplayString(marketAreaGroupById(Number(item.value))?.title), 1)
                                                                                    ], undefined, true),
                                                                                    _: 2
                                                                                }, 1024)),
                                                                            (groupInEdit.value !== Number(item.value) &&
                                                                                !marketAreaGroupById(Number(item.value))?.isDefault)
                                                                                ? (_openBlock(), _createBlock(_component_v_col, {
                                                                                    key: 2,
                                                                                    cols: "auto"
                                                                                }, {
                                                                                    default: _withCtx(() => [
                                                                                        _createVNode(_component_v_btn, {
                                                                                            icon: "mdi-pencil",
                                                                                            size: "small",
                                                                                            variant: "text",
                                                                                            onClick: ($event) => (showEditGroupName(Number(item.value)))
                                                                                        }, null, 8, ["onClick"])
                                                                                    ], undefined, true),
                                                                                    _: 2
                                                                                }, 1024))
                                                                                : (groupInEdit.value === Number(item.value))
                                                                                    ? (_openBlock(), _createBlock(_component_v_col, {
                                                                                        key: 3,
                                                                                        cols: "auto"
                                                                                    }, {
                                                                                        default: _withCtx(() => [
                                                                                            _createVNode(_component_v_btn, {
                                                                                                icon: "mdi-check",
                                                                                                onClick: _cache[1] || (_cache[1] = ($event) => (saveGroupName())),
                                                                                                size: "small",
                                                                                                variant: "text"
                                                                                            })
                                                                                        ], undefined, true),
                                                                                        _: 1
                                                                                    }))
                                                                                    : _createCommentVNode("", true),
                                                                            (!marketAreaGroupById(Number(item.value))?.isDefault)
                                                                                ? (_openBlock(), _createBlock(_component_v_col, {
                                                                                    key: 4,
                                                                                    cols: "auto"
                                                                                }, {
                                                                                    default: _withCtx(() => [
                                                                                        _createVNode(_component_v_btn, {
                                                                                            icon: "mdi-delete",
                                                                                            onClick: ($event) => (removeGroup(Number(item.value))),
                                                                                            size: "small",
                                                                                            variant: "text"
                                                                                        }, null, 8, ["onClick"])
                                                                                    ], undefined, true),
                                                                                    _: 2
                                                                                }, 1024))
                                                                                : _createCommentVNode("", true),
                                                                            _createVNode(_component_v_col, {
                                                                                class: "ml-auto",
                                                                                cols: "auto"
                                                                            }, {
                                                                                default: _withCtx(() => [
                                                                                    _createVNode(_component_v_tooltip, { location: "top" }, {
                                                                                        activator: _withCtx(({ props }) => [
                                                                                            _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                                                                                _createVNode(_component_v_btn, {
                                                                                                    icon: "mdi-plus-circle",
                                                                                                    disabled: !selectedRows.value.length,
                                                                                                    size: "small",
                                                                                                    variant: "text",
                                                                                                    onClick: ($event) => (moveToGroup(Number(item.value)))
                                                                                                }, null, 8, ["disabled", "onClick"])
                                                                                            ], 16)
                                                                                        ]),
                                                                                        default: _withCtx(() => [
                                                                                            _cache[2] || (_cache[2] = _createElementVNode("span", null, _toDisplayString("Ausgewählte Bereiche in diese Gruppe verschieben"), -1))
                                                                                        ], undefined, true),
                                                                                        _: 2
                                                                                    }, 1024)
                                                                                ], undefined, true),
                                                                                _: 2
                                                                            }, 1024)
                                                                        ], undefined, true),
                                                                        _: 2
                                                                    }, 1024)
                                                                ], 8, _hoisted_1)
                                                            ])
                                                        ]),
                                                        item: _withCtx(({ item }) => [
                                                            _createElementVNode("tr", {
                                                                class: "area-row",
                                                                onMouseover: ($event) => (highlightItem({ area: item, highlight: true })),
                                                                onMouseleave: ($event) => (highlightItem({ area: item, highlight: false }))
                                                            }, [
                                                                _createElementVNode("td", null, [
                                                                    (item.kgs)
                                                                        ? (_openBlock(), _createBlock(_component_v_checkbox, {
                                                                            key: 0,
                                                                            density: "compact",
                                                                            "hide-details": "",
                                                                            "model-value": !!selectedRows.value.find((i) => i.kgs === item.kgs),
                                                                            "onUpdate:modelValue": ($event) => (toggleRowSelection(item))
                                                                        }, null, 8, ["model-value", "onUpdate:modelValue"]))
                                                                        : _createCommentVNode("", true)
                                                                ]),
                                                                _createElementVNode("td", null, [
                                                                    (item.areaType === 'plz')
                                                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                                            _createTextVNode(_toDisplayString(_unref($t)("areaType.abbreviation.plz")) + " - " + _toDisplayString(item.kgs) + " - " + _toDisplayString(item.title), 1)
                                                                        ], 64))
                                                                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                                            (item.areaType === 'kgs11' || item.areaType === 'kgs14')
                                                                                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(`${item.licensePlate ? `${item.licensePlate} - ` : ""}`), 1))
                                                                                : _createCommentVNode("", true),
                                                                            _createTextVNode(_toDisplayString(item.title), 1)
                                                                        ], 64))
                                                                ]),
                                                                _createElementVNode("td", _hoisted_4, [
                                                                    (item.title !== 'Summe')
                                                                        ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                                                            key: 0,
                                                                            location: "top"
                                                                        }, {
                                                                            activator: _withCtx(({ props }) => [
                                                                                _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), _toDisplayString(_unref($t)(`areaType.abbreviation.${item.areaType}`)), 17)
                                                                            ]),
                                                                            default: _withCtx(() => [
                                                                                _createElementVNode("span", null, _toDisplayString(_unref($t)(`areaType.fullname.${item.areaType}`)), 1)
                                                                            ], undefined, true),
                                                                            _: 2
                                                                        }, 1024))
                                                                        : _createCommentVNode("", true)
                                                                ]),
                                                                (showEvalAmount.value && item.areaType !== 'plz')
                                                                    ? (_openBlock(), _createElementBlock("td", _hoisted_5, [
                                                                        _createVNode(EvaluationAmountRenderer, {
                                                                            value: item.offersAppartmentBuy
                                                                        }, {
                                                                            default: _withCtx(() => [
                                                                                _createTextVNode(_toDisplayString(formatNumber(item.offersAppartmentBuy, 0, 0)), 1)
                                                                            ], undefined, true),
                                                                            _: 2
                                                                        }, 1032, ["value"])
                                                                    ]))
                                                                    : _createCommentVNode("", true),
                                                                (showEvalAmount.value && item.areaType !== 'plz')
                                                                    ? (_openBlock(), _createElementBlock("td", _hoisted_6, [
                                                                        _createVNode(EvaluationAmountRenderer, {
                                                                            value: item.offersAppartmentRent
                                                                        }, {
                                                                            default: _withCtx(() => [
                                                                                _createTextVNode(_toDisplayString(formatNumber(item.offersAppartmentRent, 0, 0)), 1)
                                                                            ], undefined, true),
                                                                            _: 2
                                                                        }, 1032, ["value"])
                                                                    ]))
                                                                    : _createCommentVNode("", true),
                                                                (showEvalAmount.value && item.areaType !== 'plz')
                                                                    ? (_openBlock(), _createElementBlock("td", _hoisted_7, [
                                                                        _createVNode(EvaluationAmountRenderer, {
                                                                            value: item.offersHouseBuy
                                                                        }, {
                                                                            default: _withCtx(() => [
                                                                                _createTextVNode(_toDisplayString(formatNumber(item.offersHouseBuy, 0, 0)), 1)
                                                                            ], undefined, true),
                                                                            _: 2
                                                                        }, 1032, ["value"])
                                                                    ]))
                                                                    : _createCommentVNode("", true),
                                                                (showEvalAmount.value && item.areaType !== 'plz')
                                                                    ? (_openBlock(), _createElementBlock("td", _hoisted_8, [
                                                                        _createVNode(EvaluationAmountRenderer, {
                                                                            value: item.offersHouseRent
                                                                        }, {
                                                                            default: _withCtx(() => [
                                                                                _createTextVNode(_toDisplayString(formatNumber(item.offersHouseRent, 0, 0)), 1)
                                                                            ], undefined, true),
                                                                            _: 2
                                                                        }, 1032, ["value"])
                                                                    ]))
                                                                    : _createCommentVNode("", true),
                                                                _createElementVNode("td", _hoisted_9, [
                                                                    (item.population)
                                                                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(formatNumber(item.population, 0, 0)), 1))
                                                                        : _createCommentVNode("", true)
                                                                ]),
                                                                _createElementVNode("td", _hoisted_11, [
                                                                    (item.kgs)
                                                                        ? (_openBlock(), _createBlock(_component_v_btn, {
                                                                            key: 0,
                                                                            class: "delete-button",
                                                                            variant: "text",
                                                                            density: "compact",
                                                                            icon: "mdi-delete",
                                                                            onClick: ($event) => (toggleSelectedArea({ area: item, delete: true }))
                                                                        }, null, 8, ["onClick"]))
                                                                        : _createCommentVNode("", true)
                                                                ])
                                                            ], 40, _hoisted_2)
                                                        ]),
                                                        _: 1
                                                    }, 8, ["headers", "items"])
                                                ], undefined, true),
                                                _: 1
                                            })
                                        ], undefined, true),
                                        _: 1
                                    })
                                ], undefined, true),
                                _: 1
                            })
                        ], undefined, true),
                        _: 1
                    }),
                    (!_unref(isAreaReadOnly)(initialMarkedArea.value))
                        ? (_openBlock(), _createBlock(_component_v_row, {
                            key: 0,
                            justify: "center",
                            align: "end"
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "auto" }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_v_btn, {
                                            color: "primary",
                                            onClick: createMarketAreaGroup
                                        }, {
                                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                                                _createTextVNode(" Neue Gruppe ")
                                            ]), undefined, true),
                                            _: 1
                                        })
                                    ], undefined, true),
                                    _: 1
                                })
                            ], undefined, true),
                            _: 1
                        }))
                        : _createCommentVNode("", true)
                ], undefined, true),
                _: 1
            }));
        };
    }
});
