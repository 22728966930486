/* tslint:disable */
/* eslint-disable */
/**
 * MaGic API
 * API docs for MaGic
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setBearerAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction, } from "./common";
// @ts-ignore
import { BASE_PATH, BaseAPI } from "./base";
/**
 *
 * @export
 * @enum {string}
 */
export var AreaState;
(function (AreaState) {
    AreaState["inProgress"] = "inProgress";
    AreaState["inPreparation"] = "inPreparation";
    AreaState["approved"] = "approved";
})(AreaState || (AreaState = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var AreaType;
(function (AreaType) {
    AreaType["kgs2"] = "kgs2";
    AreaType["kgs5"] = "kgs5";
    AreaType["vgs"] = "vgs";
    AreaType["plz"] = "plz";
    AreaType["kgs8"] = "kgs8";
    AreaType["kgs11"] = "kgs11";
    AreaType["kgs14"] = "kgs14";
})(AreaType || (AreaType = {}));
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetPrices: async (options = {}) => {
            const localVarPath = `/api/v1/prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: "GET",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetProtected: async (options = {}) => {
            const localVarPath = `/api/v1/protected`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: "GET",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetPrices(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetPrices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetProtected(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetProtected(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};
/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration, basePath, axios) {
    const localVarFp = DefaultApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetPrices(options) {
            return localVarFp
                .appControllerGetPrices(options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetProtected(options) {
            return localVarFp
                .appControllerGetProtected(options)
                .then((request) => request(axios, basePath));
        },
    };
};
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    appControllerGetPrices(options) {
        return DefaultApiFp(this.configuration)
            .appControllerGetPrices(options)
            .then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    appControllerGetProtected(options) {
        return DefaultApiFp(this.configuration)
            .appControllerGetProtected(options)
            .then((request) => request(this.axios, this.basePath));
    }
}
/**
 * GeometryApi - axios parameter creator
 * @export
 */
export const GeometryApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary retrieve areas in region
         * @param {'kgs2' | 'kgs5' | 'vgs' | 'plz' | 'kgs8' | 'kgs11' | 'kgs14'} areaType
         * @param {Array<number>} bounds Bounding box coordinates in latitude/longitude pairs
         * @param {Array<number>} [previousBounds] Bounding box coordinates in latitude/longitude pairs of previous request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geometryControllerGetAreas: async (areaType, bounds, previousBounds, options = {}) => {
            // verify required parameter 'areaType' is not null or undefined
            assertParamExists("geometryControllerGetAreas", "areaType", areaType);
            // verify required parameter 'bounds' is not null or undefined
            assertParamExists("geometryControllerGetAreas", "bounds", bounds);
            const localVarPath = `/api/v1/geometry/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: "GET",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (areaType !== undefined) {
                localVarQueryParameter["areaType"] = areaType;
            }
            if (bounds) {
                localVarQueryParameter["bounds"] = bounds;
            }
            if (previousBounds) {
                localVarQueryParameter["previousBounds"] = previousBounds;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * GeometryApi - functional programming interface
 * @export
 */
export const GeometryApiFp = function (configuration) {
    const localVarAxiosParamCreator = GeometryApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary retrieve areas in region
         * @param {'kgs2' | 'kgs5' | 'vgs' | 'plz' | 'kgs8' | 'kgs11' | 'kgs14'} areaType
         * @param {Array<number>} bounds Bounding box coordinates in latitude/longitude pairs
         * @param {Array<number>} [previousBounds] Bounding box coordinates in latitude/longitude pairs of previous request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geometryControllerGetAreas(areaType, bounds, previousBounds, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geometryControllerGetAreas(areaType, bounds, previousBounds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};
/**
 * GeometryApi - factory interface
 * @export
 */
export const GeometryApiFactory = function (configuration, basePath, axios) {
    const localVarFp = GeometryApiFp(configuration);
    return {
        /**
         *
         * @summary retrieve areas in region
         * @param {'kgs2' | 'kgs5' | 'vgs' | 'plz' | 'kgs8' | 'kgs11' | 'kgs14'} areaType
         * @param {Array<number>} bounds Bounding box coordinates in latitude/longitude pairs
         * @param {Array<number>} [previousBounds] Bounding box coordinates in latitude/longitude pairs of previous request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geometryControllerGetAreas(areaType, bounds, previousBounds, options) {
            return localVarFp
                .geometryControllerGetAreas(areaType, bounds, previousBounds, options)
                .then((request) => request(axios, basePath));
        },
    };
};
/**
 * GeometryApi - object-oriented interface
 * @export
 * @class GeometryApi
 * @extends {BaseAPI}
 */
export class GeometryApi extends BaseAPI {
    /**
     *
     * @summary retrieve areas in region
     * @param {'kgs2' | 'kgs5' | 'vgs' | 'plz' | 'kgs8' | 'kgs11' | 'kgs14'} areaType
     * @param {Array<number>} bounds Bounding box coordinates in latitude/longitude pairs
     * @param {Array<number>} [previousBounds] Bounding box coordinates in latitude/longitude pairs of previous request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryApi
     */
    geometryControllerGetAreas(areaType, bounds, previousBounds, options) {
        return GeometryApiFp(this.configuration)
            .geometryControllerGetAreas(areaType, bounds, previousBounds, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
/**
 * MarketareaApi - axios parameter creator
 * @export
 */
export const MarketareaApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {CreateMarketAreaDto} createMarketAreaDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAreasControllerCreate: async (createMarketAreaDto, options = {}) => {
            // verify required parameter 'createMarketAreaDto' is not null or undefined
            assertParamExists("marketAreasControllerCreate", "createMarketAreaDto", createMarketAreaDto);
            const localVarPath = `/api/v1/marketareas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: "POST",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter["Content-Type"] = "application/json";
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(createMarketAreaDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAreasControllerExportMarketAreaById: async (id, options = {}) => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists("marketAreasControllerExportMarketAreaById", "id", id);
            const localVarPath = `/api/v1/marketareas/{id}/export`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: "GET",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAreasControllerFindAll: async (sort, options = {}) => {
            // verify required parameter 'sort' is not null or undefined
            assertParamExists("marketAreasControllerFindAll", "sort", sort);
            const localVarPath = `/api/v1/marketareas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: "GET",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (sort !== undefined) {
                localVarQueryParameter["sort"] = sort;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAreasControllerFindOne: async (id, options = {}) => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists("marketAreasControllerFindOne", "id", id);
            const localVarPath = `/api/v1/marketareas/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: "GET",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAreasControllerFindOneWithDetailedGeometry: async (id, options = {}) => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists("marketAreasControllerFindOneWithDetailedGeometry", "id", id);
            const localVarPath = `/api/v1/marketareas/{id}/detail`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: "GET",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAreasControllerRemove: async (id, options = {}) => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists("marketAreasControllerRemove", "id", id);
            const localVarPath = `/api/v1/marketareas/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: "DELETE",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {UpdateMarketAreaDto} updateMarketAreaDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAreasControllerUpdate: async (id, updateMarketAreaDto, options = {}) => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists("marketAreasControllerUpdate", "id", id);
            // verify required parameter 'updateMarketAreaDto' is not null or undefined
            assertParamExists("marketAreasControllerUpdate", "updateMarketAreaDto", updateMarketAreaDto);
            const localVarPath = `/api/v1/marketareas/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: "PATCH",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter["Content-Type"] = "application/json";
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(updateMarketAreaDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAreasControllerUpdateState: async (id, options = {}) => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists("marketAreasControllerUpdateState", "id", id);
            const localVarPath = `/api/v1/marketareas/{id}/state`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: "PATCH",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * MarketareaApi - functional programming interface
 * @export
 */
export const MarketareaApiFp = function (configuration) {
    const localVarAxiosParamCreator = MarketareaApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {CreateMarketAreaDto} createMarketAreaDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketAreasControllerCreate(createMarketAreaDto, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketAreasControllerCreate(createMarketAreaDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketAreasControllerExportMarketAreaById(id, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketAreasControllerExportMarketAreaById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketAreasControllerFindAll(sort, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketAreasControllerFindAll(sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketAreasControllerFindOne(id, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketAreasControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketAreasControllerFindOneWithDetailedGeometry(id, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketAreasControllerFindOneWithDetailedGeometry(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketAreasControllerRemove(id, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketAreasControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {UpdateMarketAreaDto} updateMarketAreaDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketAreasControllerUpdate(id, updateMarketAreaDto, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketAreasControllerUpdate(id, updateMarketAreaDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketAreasControllerUpdateState(id, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketAreasControllerUpdateState(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};
/**
 * MarketareaApi - factory interface
 * @export
 */
export const MarketareaApiFactory = function (configuration, basePath, axios) {
    const localVarFp = MarketareaApiFp(configuration);
    return {
        /**
         *
         * @param {CreateMarketAreaDto} createMarketAreaDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAreasControllerCreate(createMarketAreaDto, options) {
            return localVarFp
                .marketAreasControllerCreate(createMarketAreaDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAreasControllerExportMarketAreaById(id, options) {
            return localVarFp
                .marketAreasControllerExportMarketAreaById(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAreasControllerFindAll(sort, options) {
            return localVarFp
                .marketAreasControllerFindAll(sort, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAreasControllerFindOne(id, options) {
            return localVarFp
                .marketAreasControllerFindOne(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAreasControllerFindOneWithDetailedGeometry(id, options) {
            return localVarFp
                .marketAreasControllerFindOneWithDetailedGeometry(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAreasControllerRemove(id, options) {
            return localVarFp
                .marketAreasControllerRemove(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {UpdateMarketAreaDto} updateMarketAreaDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAreasControllerUpdate(id, updateMarketAreaDto, options) {
            return localVarFp
                .marketAreasControllerUpdate(id, updateMarketAreaDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAreasControllerUpdateState(id, options) {
            return localVarFp
                .marketAreasControllerUpdateState(id, options)
                .then((request) => request(axios, basePath));
        },
    };
};
/**
 * MarketareaApi - object-oriented interface
 * @export
 * @class MarketareaApi
 * @extends {BaseAPI}
 */
export class MarketareaApi extends BaseAPI {
    /**
     *
     * @param {CreateMarketAreaDto} createMarketAreaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketareaApi
     */
    marketAreasControllerCreate(createMarketAreaDto, options) {
        return MarketareaApiFp(this.configuration)
            .marketAreasControllerCreate(createMarketAreaDto, options)
            .then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketareaApi
     */
    marketAreasControllerExportMarketAreaById(id, options) {
        return MarketareaApiFp(this.configuration)
            .marketAreasControllerExportMarketAreaById(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketareaApi
     */
    marketAreasControllerFindAll(sort, options) {
        return MarketareaApiFp(this.configuration)
            .marketAreasControllerFindAll(sort, options)
            .then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketareaApi
     */
    marketAreasControllerFindOne(id, options) {
        return MarketareaApiFp(this.configuration)
            .marketAreasControllerFindOne(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketareaApi
     */
    marketAreasControllerFindOneWithDetailedGeometry(id, options) {
        return MarketareaApiFp(this.configuration)
            .marketAreasControllerFindOneWithDetailedGeometry(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketareaApi
     */
    marketAreasControllerRemove(id, options) {
        return MarketareaApiFp(this.configuration)
            .marketAreasControllerRemove(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {UpdateMarketAreaDto} updateMarketAreaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketareaApi
     */
    marketAreasControllerUpdate(id, updateMarketAreaDto, options) {
        return MarketareaApiFp(this.configuration)
            .marketAreasControllerUpdate(id, updateMarketAreaDto, options)
            .then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketareaApi
     */
    marketAreasControllerUpdateState(id, options) {
        return MarketareaApiFp(this.configuration)
            .marketAreasControllerUpdateState(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
