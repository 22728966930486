import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import * as localforage from "localforage";
import { getPrices } from "../api/resources";
import { set } from "./helpers";
import { marketAreaOverviewModule } from "./marketareaoverview";
import { marketAreaModule } from "./marketarea";
import { clone } from "pouchdb-utils";
const vuexLocal = new VuexPersistence({
    storage: localforage,
    asyncStorage: true,
    modules: ["marketArea"],
    reducer: (state) => clone(state),
});
export var RootMutations;
(function (RootMutations) {
    RootMutations["setPrices"] = "setPrices";
    RootMutations["setToken"] = "setToken";
})(RootMutations || (RootMutations = {}));
export var RootActions;
(function (RootActions) {
    RootActions["getPrices"] = "getPrices";
    RootActions["getToken"] = "getToken";
    RootActions["clearAll"] = "clearAll";
})(RootActions || (RootActions = {}));
const store = createStore({
    modules: {
        marketArea: marketAreaModule,
        marketAreaOverview: marketAreaOverviewModule,
    },
    state: {
        prices: {
            basePrice: 3000,
            pricePerUnit: 80,
        },
        token: "",
    },
    plugins: [vuexLocal.plugin],
    mutations: {
        [RootMutations.setPrices]: set("prices"),
        [RootMutations.setToken]: set("token"),
    },
    actions: {
        [RootActions.getPrices]: async ({ commit }) => {
            commit(RootMutations.setPrices, (await getPrices()).data);
        },
        [RootActions.clearAll]: async () => {
            await localforage.clear();
        },
    },
});
store.dispatch(RootActions.getPrices);
export default store;
