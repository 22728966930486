import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList } from "vue";
const _hoisted_1 = { class: "primary--text" };
const _hoisted_2 = { class: "primary--text" };
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { MarketAreaActions, MarketAreaGetters, MarketAreaMutations, MarketAreaState, } from "../../store/marketarea/types";
import { useRole } from "@/mixins/role";
import { useI18n } from "vue-i18n";
export default /*@__PURE__*/ _defineComponent({
    __name: 'Toolbar',
    setup(__props) {
        const store = useStore();
        const { isAdmin } = useRole();
        const { t } = useI18n();
        const currentMap = computed(() => store.state.marketArea[MarketAreaState.currentMap]);
        const activeLayer = computed(() => store.state.marketArea[MarketAreaState.activeLayer]);
        const activeArea = computed(() => store.state.marketArea[MarketAreaState.activeArea]);
        const showDetail = computed(() => store.state.marketArea[MarketAreaState.showDetail]);
        const additionalVisibleLayers = computed(() => store.state.marketArea[MarketAreaState.additionalVisibleLayers]);
        const loadAreas = computed(() => store.getters[MarketAreaGetters.loadAreas]);
        const updateActiveArea = (val) => {
            store.dispatch(MarketAreaActions.updateActiveArea, val);
        };
        const toggleAdditionalVisibleLayer = (val) => {
            store.dispatch(MarketAreaActions.toggleAdditionalVisibleLayer, val);
        };
        const showDetailView = (payload) => {
            store.dispatch(MarketAreaActions.showDetailView, payload);
        };
        const setCurrentMap = (val) => {
            store.commit(MarketAreaMutations.setCurrentMap, val);
        };
        const setActiveLayer = (val) => {
            store.commit(MarketAreaMutations.setActiveLayer, val);
        };
        onMounted(() => {
            if (!isAdmin.value) {
                setActiveLayer("pricelayer_none");
            }
        });
        const maps = [
            {
                title: "iib-wohnlagenkarte_kd7g",
                value: "iib-wohnlagenkarte_kd7g",
            },
            {
                title: "iib-sw_style",
                value: "iib-sw_style",
            },
            {
                title: "open-street-map",
                value: "open-street-map",
            },
        ];
        const priceLayers = [
            {
                title: "label_house_buy",
                value: "label_house_buy",
            },
            {
                title: "label_appartment_buy",
                value: "label_appartment_buy",
            },
            {
                title: "label_house_rent",
                value: "label_house_rent",
            },
            {
                title: "label_appartment_rent",
                value: "label_appartment_rent",
            },
            {
                title: "pricelayer_none",
                value: "pricelayer_none",
            },
        ];
        return (_ctx, _cache) => {
            const _component_v_btn = _resolveComponent("v-btn");
            const _component_v_btn_toggle = _resolveComponent("v-btn-toggle");
            const _component_v_tooltip = _resolveComponent("v-tooltip");
            const _component_v_icon = _resolveComponent("v-icon");
            const _component_v_list_item_title = _resolveComponent("v-list-item-title");
            const _component_v_list_item = _resolveComponent("v-list-item");
            const _component_v_list = _resolveComponent("v-list");
            const _component_v_menu = _resolveComponent("v-menu");
            const _component_v_toolbar = _resolveComponent("v-toolbar");
            return (_openBlock(), _createBlock(_component_v_toolbar, {
                class: "map-toolbar",
                color: "transparent",
                flat: "",
                absolute: "",
                width: "60%",
                density: "compact",
                style: { "top": "64px", "left": "65px", "z-index": "1", "font-size": "10px" }
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_v_tooltip, { location: "bottom" }, {
                        activator: _withCtx(({ props }) => [
                            _createVNode(_component_v_btn_toggle, { density: "compact" }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_v_btn, _mergeProps(props, {
                                        density: "compact",
                                        icon: 'mdi-eye' + (showDetail.value ? '-off' : '') + '-outline'
                                    }, {
                                        onClick: _cache[0] || (_cache[0] = ($event) => (showDetailView({ detail: !showDetail.value })))
                                    }), null, 16, ["icon"])
                                ], undefined, true),
                                _: 2
                            }, 1024)
                        ]),
                        default: _withCtx(() => [
                            _cache[13] || (_cache[13] = _createElementVNode("span", null, " Hier können Sie sich Ihr fertig ausgewähltes Gebiet exakt anzeigen lassen ", -1))
                        ], undefined, true),
                        _: 1
                    }),
                    (activeArea.value !== 'plz')
                        ? (_openBlock(), _createBlock(_component_v_btn_toggle, {
                            key: 0,
                            density: "compact",
                            color: "primary"
                        }, {
                            default: _withCtx(() => [
                                (_unref(isAdmin))
                                    ? (_openBlock(), _createBlock(_component_v_btn, {
                                        key: 0,
                                        class: _normalizeClass([{ 'v-btn--active': activeArea.value === 'kgs2' }, "btn-layer-switch"]),
                                        onClick: _cache[2] || (_cache[2] = ($event) => (updateActiveArea('kgs2'))),
                                        density: "compact",
                                        disabled: loadAreas.value
                                    }, {
                                        default: _withCtx(() => [
                                            _cache[16] || (_cache[16] = _createTextVNode(" KGS2 ")),
                                            (activeArea.value !== 'kgs2')
                                                ? (_openBlock(), _createBlock(_component_v_btn, {
                                                    key: 0,
                                                    class: "btn-layer-toggle",
                                                    icon: "",
                                                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event) => (toggleAdditionalVisibleLayer('kgs2')), ["stop"])),
                                                    density: "compact",
                                                    variant: "text"
                                                }, {
                                                    default: _withCtx(() => [
                                                        (additionalVisibleLayers.value.includes('kgs2'))
                                                            ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                                                                default: _withCtx(() => _cache[14] || (_cache[14] = [
                                                                    _createTextVNode("mdi-eye-off-outline")
                                                                ]), undefined, true),
                                                                _: 1
                                                            }))
                                                            : (_openBlock(), _createBlock(_component_v_icon, { key: 1 }, {
                                                                default: _withCtx(() => _cache[15] || (_cache[15] = [
                                                                    _createTextVNode("mdi-eye-outline")
                                                                ]), undefined, true),
                                                                _: 1
                                                            }))
                                                    ], undefined, true),
                                                    _: 1
                                                }))
                                                : _createCommentVNode("", true)
                                        ], undefined, true),
                                        _: 1
                                    }, 8, ["class", "disabled"]))
                                    : _createCommentVNode("", true),
                                (_unref(isAdmin))
                                    ? (_openBlock(), _createBlock(_component_v_btn, {
                                        key: 1,
                                        class: _normalizeClass([{ 'v-btn--active': activeArea.value === 'kgs5' }, "btn-layer-switch"]),
                                        onClick: _cache[4] || (_cache[4] = ($event) => (updateActiveArea('kgs5'))),
                                        disabled: loadAreas.value,
                                        density: "compact"
                                    }, {
                                        default: _withCtx(() => [
                                            _cache[19] || (_cache[19] = _createTextVNode(" KGS5 ")),
                                            (activeArea.value !== 'kgs5')
                                                ? (_openBlock(), _createBlock(_component_v_btn, {
                                                    key: 0,
                                                    class: "btn-layer-toggle",
                                                    icon: "",
                                                    onClick: _cache[3] || (_cache[3] = _withModifiers(($event) => (toggleAdditionalVisibleLayer('kgs5')), ["stop"])),
                                                    density: "compact",
                                                    variant: "text"
                                                }, {
                                                    default: _withCtx(() => [
                                                        (additionalVisibleLayers.value.includes('kgs5'))
                                                            ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                                                                default: _withCtx(() => _cache[17] || (_cache[17] = [
                                                                    _createTextVNode("mdi-eye-off-outline")
                                                                ]), undefined, true),
                                                                _: 1
                                                            }))
                                                            : (_openBlock(), _createBlock(_component_v_icon, { key: 1 }, {
                                                                default: _withCtx(() => _cache[18] || (_cache[18] = [
                                                                    _createTextVNode("mdi-eye-outline")
                                                                ]), undefined, true),
                                                                _: 1
                                                            }))
                                                    ], undefined, true),
                                                    _: 1
                                                }))
                                                : _createCommentVNode("", true)
                                        ], undefined, true),
                                        _: 1
                                    }, 8, ["class", "disabled"]))
                                    : _createCommentVNode("", true),
                                _createVNode(_component_v_btn, {
                                    class: _normalizeClass([{ 'v-btn--active': activeArea.value === 'vgs' }, "btn-layer-switch"]),
                                    onClick: _cache[6] || (_cache[6] = ($event) => (updateActiveArea('vgs'))),
                                    disabled: loadAreas.value,
                                    density: "compact"
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(isAdmin) ? "VGS" : _unref(t)("areaType.fullname.vgs")) + " ", 1),
                                        (activeArea.value !== 'vgs')
                                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                                key: 0,
                                                class: "btn-layer-toggle",
                                                icon: "",
                                                onClick: _cache[5] || (_cache[5] = _withModifiers(($event) => (toggleAdditionalVisibleLayer('vgs')), ["stop"])),
                                                density: "compact",
                                                variant: "text"
                                            }, {
                                                default: _withCtx(() => [
                                                    (additionalVisibleLayers.value.includes('vgs'))
                                                        ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                                                            default: _withCtx(() => _cache[20] || (_cache[20] = [
                                                                _createTextVNode("mdi-eye-off-outline")
                                                            ]), undefined, true),
                                                            _: 1
                                                        }))
                                                        : (_openBlock(), _createBlock(_component_v_icon, { key: 1 }, {
                                                            default: _withCtx(() => _cache[21] || (_cache[21] = [
                                                                _createTextVNode("mdi-eye-outline")
                                                            ]), undefined, true),
                                                            _: 1
                                                        }))
                                                ], undefined, true),
                                                _: 1
                                            }))
                                            : _createCommentVNode("", true)
                                    ], undefined, true),
                                    _: 1
                                }, 8, ["class", "disabled"]),
                                _createVNode(_component_v_btn, {
                                    class: _normalizeClass([{ 'v-btn--active': activeArea.value === 'kgs8' }, "btn-layer-switch"]),
                                    onClick: _cache[8] || (_cache[8] = ($event) => (updateActiveArea('kgs8'))),
                                    disabled: loadAreas.value,
                                    density: "compact"
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(isAdmin) ? "KGS8" : _unref(t)("areaType.fullname.kgs8")) + " ", 1),
                                        (activeArea.value !== 'kgs8')
                                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                                key: 0,
                                                class: "btn-layer-toggle",
                                                icon: "",
                                                onClick: _cache[7] || (_cache[7] = _withModifiers(($event) => (toggleAdditionalVisibleLayer('kgs8')), ["stop"])),
                                                density: "compact",
                                                variant: "text"
                                            }, {
                                                default: _withCtx(() => [
                                                    (additionalVisibleLayers.value.includes('kgs8'))
                                                        ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                                                            default: _withCtx(() => _cache[22] || (_cache[22] = [
                                                                _createTextVNode("mdi-eye-off-outline")
                                                            ]), undefined, true),
                                                            _: 1
                                                        }))
                                                        : (_openBlock(), _createBlock(_component_v_icon, { key: 1 }, {
                                                            default: _withCtx(() => _cache[23] || (_cache[23] = [
                                                                _createTextVNode("mdi-eye-outline")
                                                            ]), undefined, true),
                                                            _: 1
                                                        }))
                                                ], undefined, true),
                                                _: 1
                                            }))
                                            : _createCommentVNode("", true)
                                    ], undefined, true),
                                    _: 1
                                }, 8, ["class", "disabled"]),
                                _createVNode(_component_v_btn, {
                                    class: _normalizeClass([{ 'v-btn--active': activeArea.value === 'kgs11' }, "btn-layer-switch"]),
                                    onClick: _cache[10] || (_cache[10] = ($event) => (updateActiveArea('kgs11'))),
                                    disabled: loadAreas.value,
                                    density: "compact"
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(isAdmin) ? "KGS11" : _unref(t)("areaType.fullname.kgs11")) + " ", 1),
                                        (activeArea.value !== 'kgs11')
                                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                                key: 0,
                                                class: "btn-layer-toggle",
                                                icon: "",
                                                onClick: _cache[9] || (_cache[9] = _withModifiers(($event) => (toggleAdditionalVisibleLayer('kgs11')), ["stop"])),
                                                density: "compact",
                                                variant: "text"
                                            }, {
                                                default: _withCtx(() => [
                                                    (additionalVisibleLayers.value.includes('kgs11'))
                                                        ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                                                            default: _withCtx(() => _cache[24] || (_cache[24] = [
                                                                _createTextVNode("mdi-eye-off-outline")
                                                            ]), undefined, true),
                                                            _: 1
                                                        }))
                                                        : (_openBlock(), _createBlock(_component_v_icon, { key: 1 }, {
                                                            default: _withCtx(() => _cache[25] || (_cache[25] = [
                                                                _createTextVNode("mdi-eye-outline")
                                                            ]), undefined, true),
                                                            _: 1
                                                        }))
                                                ], undefined, true),
                                                _: 1
                                            }))
                                            : _createCommentVNode("", true)
                                    ], undefined, true),
                                    _: 1
                                }, 8, ["class", "disabled"]),
                                _createVNode(_component_v_btn, {
                                    class: _normalizeClass([{ 'v-btn--active': activeArea.value === 'kgs14' }, "btn-layer-switch"]),
                                    onClick: _cache[12] || (_cache[12] = ($event) => (updateActiveArea('kgs14'))),
                                    disabled: loadAreas.value,
                                    density: "compact"
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(isAdmin) ? "KGS14" : _unref(t)("areaType.fullname.kgs14")) + " ", 1),
                                        (activeArea.value !== 'kgs14')
                                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                                key: 0,
                                                class: "btn-layer-toggle",
                                                icon: "",
                                                onClick: _cache[11] || (_cache[11] = _withModifiers(($event) => (toggleAdditionalVisibleLayer('kgs14')), ["stop"])),
                                                density: "compact",
                                                variant: "text"
                                            }, {
                                                default: _withCtx(() => [
                                                    (additionalVisibleLayers.value.includes('kgs14'))
                                                        ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                                                            default: _withCtx(() => _cache[26] || (_cache[26] = [
                                                                _createTextVNode("mdi-eye-off-outline")
                                                            ]), undefined, true),
                                                            _: 1
                                                        }))
                                                        : (_openBlock(), _createBlock(_component_v_icon, { key: 1 }, {
                                                            default: _withCtx(() => _cache[27] || (_cache[27] = [
                                                                _createTextVNode("mdi-eye-outline")
                                                            ]), undefined, true),
                                                            _: 1
                                                        }))
                                                ], undefined, true),
                                                _: 1
                                            }))
                                            : _createCommentVNode("", true)
                                    ], undefined, true),
                                    _: 1
                                }, 8, ["class", "disabled"])
                            ], undefined, true),
                            _: 1
                        }))
                        : _createCommentVNode("", true),
                    _createVNode(_component_v_btn_toggle, { density: "compact" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_v_menu, { "offset-y": "" }, {
                                activator: _withCtx(({ props }) => [
                                    _createVNode(_component_v_btn, _mergeProps(props, {
                                        width: 200,
                                        color: "primary",
                                        density: "compact"
                                    }), {
                                        default: _withCtx(() => [
                                            (currentMap.value)
                                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                    _createTextVNode(_toDisplayString(_unref(t)("map.layer." + currentMap.value)), 1)
                                                ], 64))
                                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                    _createTextVNode(" Kartenlayer ")
                                                ], 64))
                                        ], undefined, true),
                                        _: 2
                                    }, 1040)
                                ]),
                                default: _withCtx(() => [
                                    _createVNode(_component_v_list, { density: "compact" }, {
                                        default: _withCtx(() => [
                                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(maps, (map) => {
                                                return _createVNode(_component_v_list_item, {
                                                    key: `notification-key-${map.value}`,
                                                    onClick: ($event) => (setCurrentMap(map.value))
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createVNode(_component_v_list_item_title, null, {
                                                            default: _withCtx(() => [
                                                                _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(t)("map.layer." + map.title)), 1)
                                                            ], undefined, true),
                                                            _: 2
                                                        }, 1024)
                                                    ], undefined, true),
                                                    _: 2
                                                }, 1032, ["onClick"]);
                                            }), 64))
                                        ], undefined, true),
                                        _: 1
                                    })
                                ], undefined, true),
                                _: 1
                            }),
                            (_unref(isAdmin))
                                ? (_openBlock(), _createBlock(_component_v_menu, {
                                    key: 0,
                                    "offset-y": ""
                                }, {
                                    activator: _withCtx(({ props }) => [
                                        _createVNode(_component_v_btn, _mergeProps(props, {
                                            width: 200,
                                            color: "primary"
                                        }), {
                                            default: _withCtx(() => [
                                                (activeLayer.value)
                                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                        _createTextVNode(_toDisplayString(_unref(t)("map.layer." + activeLayer.value)), 1)
                                                    ], 64))
                                                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                        _createTextVNode(" Preislayer ")
                                                    ], 64))
                                            ], undefined, true),
                                            _: 2
                                        }, 1040)
                                    ]),
                                    default: _withCtx(() => [
                                        _createVNode(_component_v_list, { density: "compact" }, {
                                            default: _withCtx(() => [
                                                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(priceLayers, (priceLayer) => {
                                                    return _createVNode(_component_v_list_item, {
                                                        key: `notification-key-${priceLayer.value}`,
                                                        onClick: ($event) => (setActiveLayer(priceLayer.value))
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createVNode(_component_v_list_item_title, null, {
                                                                default: _withCtx(() => [
                                                                    _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(t)("map.layer." + priceLayer.title)), 1)
                                                                ], undefined, true),
                                                                _: 2
                                                            }, 1024)
                                                        ], undefined, true),
                                                        _: 2
                                                    }, 1032, ["onClick"]);
                                                }), 64))
                                            ], undefined, true),
                                            _: 1
                                        })
                                    ], undefined, true),
                                    _: 1
                                }))
                                : _createCommentVNode("", true)
                        ], undefined, true),
                        _: 1
                    })
                ], undefined, true),
                _: 1
            }));
        };
    }
});
