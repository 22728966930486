import { required } from "@vee-validate/rules";
import { localize } from "@vee-validate/i18n";
import { extend } from "lodash";
localize("de", {
    messages: {
        min_value: "{_field_} muss mindestens {min}m² betragen",
        construction_year: "{_field_} muss neuer als {min} sein",
    },
});
extend("required", required);
