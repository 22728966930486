import { computed } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { AreaState } from "../api/types";
export function useRole() {
    const { user } = useAuth0();
    const isAdmin = computed(() => {
        return user.value?.["https://iib-institut.de/roles"]?.includes("ROLE_MAGIC_ADMIN");
    });
    const isAreaReadOnly = (area) => {
        if (!area) {
            return false;
        }
        return isAdmin.value
            ? area.areaState === AreaState.approved
            : area.areaState !== AreaState.inProgress;
    };
    return {
        isAdmin,
        isAreaReadOnly,
    };
}
