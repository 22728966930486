import { AreaType, } from "../../api/types";
export const AreaTypeMapBoundaries = {
    [AreaType.kgs2]: {
        minZoom: 5,
        initialZoom: 7,
    },
    [AreaType.kgs5]: {
        minZoom: 8,
        initialZoom: 10,
    },
    [AreaType.vgs]: {
        minZoom: 9,
        initialZoom: 11,
    },
    [AreaType.plz]: {
        minZoom: 9,
        initialZoom: 11,
    },
    [AreaType.kgs8]: {
        minZoom: 9,
        initialZoom: 11,
    },
    [AreaType.kgs11]: {
        minZoom: 8,
        initialZoom: 9,
    },
    [AreaType.kgs14]: {
        minZoom: 8,
        initialZoom: 9,
    },
};
export const AreaTypeMapWeight = {
    [AreaType.kgs2]: 3.7,
    [AreaType.kgs5]: 2.8,
    [AreaType.vgs]: 1.6,
    [AreaType.plz]: 1.6,
    [AreaType.kgs8]: 2.4,
    [AreaType.kgs11]: 1.2,
    [AreaType.kgs14]: 1.0,
};
export var MarketAreaActions;
(function (MarketAreaActions) {
    MarketAreaActions["updateMapBounds"] = "updateMapBounds";
    MarketAreaActions["updateMapZoom"] = "updateMapZoom";
    MarketAreaActions["updateActiveArea"] = "updateActiveArea";
    MarketAreaActions["createMarketAreaGroup"] = "createMarketAreaGroup";
    MarketAreaActions["deleteMarketAreaGroup"] = "deleteMarketAreaGroup";
    MarketAreaActions["moveAreasBetweenGroups"] = "moveAreasBetweenGroups";
    MarketAreaActions["moveAreasToGroup"] = "moveAreasToGroup";
    MarketAreaActions["renameMarketAreaGroup"] = "renameMarketAreaGroup";
    MarketAreaActions["getAreas"] = "getAreas";
    MarketAreaActions["loadAdditionalVisibleAreas"] = "loadAdditionalVisibleAreas";
    MarketAreaActions["loadAllAdditionalVisibleLayers"] = "loadAllAdditionalVisibleLayers";
    MarketAreaActions["removeAdditionalVisibleAreas"] = "removeAdditionalVisibleAreas";
    MarketAreaActions["saveMarketArea"] = "saveMarketArea";
    MarketAreaActions["toggleSelectedArea"] = "toggleSelectedArea";
    MarketAreaActions["toggleHoverArea"] = "toggleHoverArea";
    MarketAreaActions["addToSelectedArea"] = "addToSelectedArea";
    MarketAreaActions["clearAll"] = "clearAll";
    MarketAreaActions["getInitialMarkedArea"] = "getInitialMarkedArea";
    MarketAreaActions["toggleAdditionalVisibleLayer"] = "toggleAdditionalVisibleLayer";
    MarketAreaActions["showDetailView"] = "showDetailView";
})(MarketAreaActions || (MarketAreaActions = {}));
export var MarketAreaGetters;
(function (MarketAreaGetters) {
    MarketAreaGetters["unselectedAreas"] = "unselectedAreas";
    MarketAreaGetters["selectedAreas"] = "selectedAreas";
    MarketAreaGetters["marketAreaGroups"] = "marketAreaGroups";
    MarketAreaGetters["marketAreaGroupById"] = "marketAreaGroupById";
    MarketAreaGetters["areasWithGroup"] = "areasWithGroup";
    MarketAreaGetters["loadAreas"] = "loadAreas";
})(MarketAreaGetters || (MarketAreaGetters = {}));
export var MarketAreaMutations;
(function (MarketAreaMutations) {
    MarketAreaMutations["setCurrentMap"] = "setCurrentMap";
    MarketAreaMutations["setActiveLayer"] = "setActiveLayer";
    MarketAreaMutations["setActiveArea"] = "setActiveArea";
    MarketAreaMutations["setPreviousBounds"] = "setPreviousBounds";
    MarketAreaMutations["setPreviousZoom"] = "setPreviousZoom";
    MarketAreaMutations["setCurrentBounds"] = "setCurrentBounds";
    MarketAreaMutations["setCurrentZoom"] = "setCurrentZoom";
    MarketAreaMutations["setLoadAreas"] = "setLoadAreas";
    MarketAreaMutations["setAreas"] = "setAreas";
    MarketAreaMutations["setMarketAreaGroups"] = "setMarketAreaGroups";
    MarketAreaMutations["setInitialMarkedArea"] = "setInitialMarkedArea";
    MarketAreaMutations["setLoadingInitialArea"] = "setLoadingInitialArea";
    MarketAreaMutations["addMarketAreaGroups"] = "addMarketAreaGroups";
    MarketAreaMutations["setShowTraffic"] = "setShowTraffic";
    MarketAreaMutations["setShowEvalAmount"] = "setShowEvalAmount";
    MarketAreaMutations["setAdditionalVisibleLayers"] = "setAdditionalVisibleLayers";
    MarketAreaMutations["setAdditionalVisibleAreas"] = "setAdditionalVisibleAreas";
    MarketAreaMutations["addAdditionalVisibleAreas"] = "addAdditionalVisibleAreas";
    MarketAreaMutations["setShowDetail"] = "setShowDetail";
    MarketAreaMutations["setDetailAreas"] = "setDetailAreas";
})(MarketAreaMutations || (MarketAreaMutations = {}));
export var MarketAreaState;
(function (MarketAreaState) {
    MarketAreaState["currentMap"] = "currentMap";
    MarketAreaState["showTraffic"] = "showTraffic";
    MarketAreaState["showEvalAmount"] = "showEvalAmount";
    MarketAreaState["activeLayer"] = "activeLayer";
    MarketAreaState["activeArea"] = "activeArea";
    MarketAreaState["additionalVisibleLayers"] = "additionalVisibleLayers";
    MarketAreaState["additionalVisibleAreas"] = "additionalVisibleAreas";
    MarketAreaState["previousBounds"] = "previousBounds";
    MarketAreaState["currentBounds"] = "currentBounds";
    MarketAreaState["previousZoom"] = "previousZoom";
    MarketAreaState["currentZoom"] = "currentZoom";
    MarketAreaState["loadAreas"] = "loadAreas";
    MarketAreaState["areas"] = "areas";
    MarketAreaState["marketAreaGroups"] = "marketAreaGroups";
    MarketAreaState["initialMarkedArea"] = "initialMarkedArea";
    MarketAreaState["loadingInitialArea"] = "loadingInitialArea";
    MarketAreaState["showDetail"] = "showDetail";
    MarketAreaState["detailAreas"] = "detailAreas";
})(MarketAreaState || (MarketAreaState = {}));
