import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue";
import { ref, reactive, computed, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useConfirm } from "vuetify-use-dialog";
import dayjs from "dayjs";
import { marketAreaResource } from "../../api/resources";
import { AreaState, AreaType, } from "../../api/types";
import { currencyFormatter } from "../../plugins/formatter";
import { useStore } from "vuex";
import { MarketAreaActions } from "../../store/marketarea/types";
import { MarketAreaOverviewActions } from "../../store/marketareaoverview";
import { useRole } from "@/mixins/role";
import { useI18n } from "vue-i18n";
import MaterialCard from "../base/MaterialCard.vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'MarketAreaDashboard',
    setup(__props) {
        const router = useRouter();
        const store = useStore();
        const createConfirm = useConfirm();
        const { isAreaReadOnly, isAdmin } = useRole();
        const { t } = useI18n();
        const copyForm = ref(false);
        const abortSignal = ref(null);
        const requiredNameRule = (value) => {
            return !!value || "Name ist ein Pflichtfeld";
        };
        const loading = ref(false);
        const options = reactive({
            page: 1,
            itemsPerPage: 10,
            sortBy: [{ key: "id", order: "desc" }],
            sortDesc: [true],
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: false,
            searchQuery: "",
        });
        const updateOptions = (newOptions) => {
            Object.assign(options, { ...options, ...newOptions });
        };
        const marketAreas = ref([]);
        const paginationMeta = reactive({
            itemCount: 0,
            totalItems: 0,
            itemsPerPage: 0,
            totalPages: 0,
            currentPage: 0,
        });
        const marketAreaToCopy = ref(null);
        const copyingMarketArea = ref(false);
        const showCopyDialog = ref(false);
        const stateKey = ref(0);
        const marketAreaToCopyKey = ref("marketAreaToCopy");
        // Vuex actions
        const prices = computed(() => store.state.prices);
        // Computed properties
        const headers = computed(() => [
            { title: "ID", value: "id", align: "start", sortable: true },
            {
                title: "Status",
                value: "areaState",
                minWidth: "275px",
                align: "center",
            },
            { title: "Name", value: "title", align: "end", sortable: true },
            {
                title: "Zul. geändert",
                value: "updatedAt",
                align: "end",
                sortable: true,
            },
            {
                title: "Anz. Teilmärkte",
                value: "areas",
                align: "end",
                sortable: false,
            },
            { title: "Kosten", value: "price", align: "end", sortable: false },
            {
                title: "Kunde",
                value: "customerName",
                align: "end",
                sortable: true,
            },
            { title: "", value: "actions", align: "end", sortable: false },
        ]);
        const areaStateValues = computed(() => {
            const commonStates = [
                {
                    title: t("areaState.inProgress"),
                    value: AreaState.inProgress,
                },
                {
                    title: t("areaState.inPreparation"),
                    value: AreaState.inPreparation,
                },
            ];
            if (!isAdmin.value)
                return commonStates;
            return [
                ...commonStates,
                {
                    title: t("areaState.approved"),
                    value: AreaState.approved,
                },
            ];
        });
        // Watchers
        watch(options, async (current, previous) => {
            if (true
            // current.page !== previous.page ||
            // current.itemsPerPage !== previous.itemsPerPage ||
            // !isEqual(current.sortBy, previous.sortBy) ||
            // !isEqual(current.sortDesc, previous.sortDesc)
            ) {
                await loadData();
            }
        });
        // Methods
        const loadData = async () => {
            loading.value = true;
            try {
                if (abortSignal.value) {
                    abortSignal.value.abort("parameters change");
                }
                abortSignal.value = new AbortController();
                const data = await store.dispatch(MarketAreaOverviewActions.load, {
                    page: options.page,
                    items: options.itemsPerPage,
                    sort: options.sortBy[0]
                        ? options.sortBy[0].order.toUpperCase()
                        : "DESC",
                    sortBy: options.sortBy[0]
                        ? options.sortBy[0].key
                        : "id",
                    searchQuery: options.searchQuery || "",
                    signal: abortSignal.value.signal,
                });
                marketAreas.value = data?.items;
                loading.value = false;
                Object.assign(paginationMeta, data?.meta);
            }
            catch (e) {
                if (e.code === "ERR_CANCELED" &&
                    e.config?.signal?.reason === "parameters change") {
                    return;
                }
                throw e;
            }
        };
        const resetAndGo = async () => {
            await store.dispatch(MarketAreaActions.clearAll);
            const res = await createConfirm({
                title: "KGS oder PLZ?",
                content: "Auswahl nach Amtlichem Gemeindeschlüssel (KGS) oder Postleitzahlen (PLZ)",
                confirmationText: "KGS",
                cancellationText: "PLZ",
            });
            await store.dispatch(MarketAreaActions.updateActiveArea, res ? AreaType.kgs8 : AreaType.plz);
            await router.push("/area");
        };
        const changeAreaState = async (value, item) => {
            if ((isAdmin.value && value === AreaState.approved) ||
                (!isAdmin.value && value === AreaState.inPreparation)) {
                const res = await createConfirm({
                    title: "Freigabe",
                    content: "Nach Freigabe kann das Marktgebiet nicht mehr verändert werden. Fortfahren?",
                    confirmationText: "Ja",
                    cancellationText: "Nein",
                });
                if (!res) {
                    stateKey.value++;
                    return;
                }
            }
            item.areaState = value;
            await marketAreaResource.patch(`${item.id}/state`, {
                areaState: value,
            });
        };
        const formatDate = (date) => date ? dayjs(date).format("DD.MM.YYYY, HH:mm:ss") : "";
        const amount = (item) => item.marketAreaGroups.filter((group) => !group.isDefault && group.selectedAreas?.length).length + (defaultGroup(item)?.selectedAreas?.length || 0);
        const defaultGroup = (item) => item.marketAreaGroups.find((group) => group.isDefault);
        const calculatePrice = (marketArea) => currencyFormatter.format(prices.value.basePrice + amount(marketArea) * prices.value.pricePerUnit);
        const copyMarketArea = async () => {
            if (!marketAreaToCopy.value)
                return;
            try {
                copyingMarketArea.value = true;
                await store.dispatch(MarketAreaActions.getInitialMarkedArea, {
                    id: marketAreaToCopy.value.id.toString(),
                    detail: false,
                });
                await store.dispatch(MarketAreaActions.saveMarketArea, {
                    marketArea: {
                        title: marketAreaToCopy.value.title,
                        customerId: marketAreaToCopy.value.customerId,
                        customerName: marketAreaToCopy.value.customerName,
                        marketAreaGroups: [],
                        activeArea: AreaType.kgs2,
                    },
                });
            }
            finally {
                copyingMarketArea.value = false;
                showCopyDialog.value = false;
            }
            loadData();
        };
        const showCopyMarketAreaConfirm = (item) => {
            marketAreaToCopy.value = { ...item };
            showCopyDialog.value = true;
            marketAreaToCopyKey.value = `marketAreaToCopy-${item.id}`;
        };
        const marketAreaItemClass = ({ item }) => {
            return item.areaState === AreaState.inProgress
                ? { class: "bg-red-lighten-5" }
                : item.areaState === AreaState.inPreparation
                    ? { class: "bg-orange-lighten-5" }
                    : { class: "bg-green-lighten-5" };
        };
        const excelExport = async (id) => {
            await store.dispatch(MarketAreaOverviewActions.excelExport, id);
        };
        onMounted(loadData);
        return (_ctx, _cache) => {
            const _component_v_text_field = _resolveComponent("v-text-field");
            const _component_v_select = _resolveComponent("v-select");
            const _component_v_btn = _resolveComponent("v-btn");
            const _component_v_col = _resolveComponent("v-col");
            const _component_v_row = _resolveComponent("v-row");
            const _component_v_container = _resolveComponent("v-container");
            const _component_v_data_table_server = _resolveComponent("v-data-table-server");
            const _component_v_card_title = _resolveComponent("v-card-title");
            const _component_v_card_actions = _resolveComponent("v-card-actions");
            const _component_v_card = _resolveComponent("v-card");
            const _component_v_card_text = _resolveComponent("v-card-text");
            const _component_v_divider = _resolveComponent("v-divider");
            const _component_v_spacer = _resolveComponent("v-spacer");
            const _component_v_form = _resolveComponent("v-form");
            const _component_v_dialog = _resolveComponent("v-dialog");
            return (_openBlock(), _createBlock(_component_v_container, null, {
                default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                    _createVNode(MaterialCard, {
                                        color: "primary",
                                        icon: "mdi-clipboard-text",
                                        inline: "",
                                        class: "px-5 py-3",
                                        title: "MaGic - Marktgebietskonfigurator"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                                modelValue: options.searchQuery,
                                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((options.searchQuery) = $event)),
                                                label: "Search by Name or Kunde"
                                            }, null, 8, ["modelValue"]),
                                            _createVNode(_component_v_data_table_server, {
                                                headers: headers.value,
                                                items: marketAreas.value,
                                                loading: loading.value,
                                                "items-per-page": options.itemsPerPage,
                                                "onUpdate:itemsPerPage": _cache[1] || (_cache[1] = ($event) => ((options.itemsPerPage) = $event)),
                                                "items-per-page-options": [5, 10, 25, 50],
                                                "items-length": paginationMeta.totalItems,
                                                "row-props": marketAreaItemClass,
                                                "onUpdate:options": updateOptions
                                            }, {
                                                "item.areaState": _withCtx(({ item }) => [
                                                    (!_unref(isAdmin) && item.areaState === 'approved')
                                                        ? (_openBlock(), _createBlock(_component_v_select, {
                                                            "model-value": item.areaState,
                                                            items: [
                                                                { title: _unref(t)('areaState.approved'), value: 'approved' },
                                                            ],
                                                            "item-title": "title",
                                                            "item-value": "value",
                                                            "onUpdate:modelValue": ($event) => (changeAreaState($event, item)),
                                                            flat: "",
                                                            variant: "outlined",
                                                            disabled: true,
                                                            key: `state-${item.id}-${stateKey.value}`,
                                                            style: { "max-width": "275px" }
                                                        }, null, 8, ["model-value", "items", "onUpdate:modelValue"]))
                                                        : (_openBlock(), _createBlock(_component_v_select, {
                                                            "model-value": item.areaState,
                                                            items: areaStateValues.value,
                                                            "item-title": "title",
                                                            "item-value": "value",
                                                            "onUpdate:modelValue": ($event) => (changeAreaState($event, item)),
                                                            flat: "",
                                                            variant: "outlined",
                                                            disabled: _unref(isAreaReadOnly)(item),
                                                            key: `state-${item.id}-${stateKey.value}`,
                                                            style: { "max-width": "275px" }
                                                        }, null, 8, ["model-value", "items", "onUpdate:modelValue", "disabled"]))
                                                ]),
                                                "item.updatedAt": _withCtx(({ item }) => [
                                                    _createTextVNode(_toDisplayString(formatDate(item.updatedAt)), 1)
                                                ]),
                                                "item.areas": _withCtx(({ item }) => [
                                                    _createTextVNode(_toDisplayString(amount(item)), 1)
                                                ]),
                                                "item.price": _withCtx(({ item }) => [
                                                    _createTextVNode(_toDisplayString(calculatePrice(item)), 1)
                                                ]),
                                                "item.customerName": _withCtx(({ item }) => [
                                                    _createTextVNode(_toDisplayString(item.customerName || item.customerId), 1)
                                                ]),
                                                "item.actions": _withCtx(({ item }) => [
                                                    _createVNode(_component_v_container, null, {
                                                        default: _withCtx(() => [
                                                            _createVNode(_component_v_row, {
                                                                align: "center",
                                                                justify: "center"
                                                            }, {
                                                                default: _withCtx(() => [
                                                                    _createVNode(_component_v_col, { cols: "auto" }, {
                                                                        default: _withCtx(() => [
                                                                            _createVNode(_component_v_btn, {
                                                                                variant: "text",
                                                                                size: "small",
                                                                                icon: _unref(isAreaReadOnly)(item) ? 'mdi-eye' : 'mdi-pencil',
                                                                                onClick: ($event) => (_unref(router).push(`/area/${item.id}`))
                                                                            }, null, 8, ["icon", "onClick"])
                                                                        ], undefined, true),
                                                                        _: 2
                                                                    }, 1024),
                                                                    _createVNode(_component_v_col, { cols: "auto" }, {
                                                                        default: _withCtx(() => [
                                                                            _createVNode(_component_v_btn, {
                                                                                variant: "text",
                                                                                size: "small",
                                                                                icon: "mdi-content-copy",
                                                                                onClick: ($event) => (showCopyMarketAreaConfirm(item))
                                                                            }, null, 8, ["onClick"])
                                                                        ], undefined, true),
                                                                        _: 2
                                                                    }, 1024)
                                                                ], undefined, true),
                                                                _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_row, {
                                                                align: "center",
                                                                justify: "center"
                                                            }, {
                                                                default: _withCtx(() => [
                                                                    _createVNode(_component_v_col, { cols: "auto" }, {
                                                                        default: _withCtx(() => [
                                                                            _createVNode(_component_v_btn, {
                                                                                icon: "mdi-printer",
                                                                                variant: "text",
                                                                                size: "small",
                                                                                to: `/print-area/${item.id}`,
                                                                                target: "_blank"
                                                                            }, null, 8, ["to"])
                                                                        ], undefined, true),
                                                                        _: 2
                                                                    }, 1024),
                                                                    _createVNode(_component_v_col, { cols: "auto" }, {
                                                                        default: _withCtx(() => [
                                                                            _createVNode(_component_v_btn, {
                                                                                variant: "text",
                                                                                size: "small",
                                                                                icon: "mdi-download",
                                                                                onClick: ($event) => (excelExport(item.id))
                                                                            }, null, 8, ["onClick"])
                                                                        ], undefined, true),
                                                                        _: 2
                                                                    }, 1024)
                                                                ], undefined, true),
                                                                _: 2
                                                            }, 1024)
                                                        ], undefined, true),
                                                        _: 2
                                                    }, 1024)
                                                ]),
                                                _: 1
                                            }, 8, ["headers", "items", "loading", "items-per-page", "items-length"])
                                        ], undefined, true),
                                        _: 1
                                    })
                                ], undefined, true),
                                _: 1
                            })
                        ], undefined, true),
                        _: 1
                    }),
                    _createVNode(_component_v_row, {
                        justify: "center",
                        class: "pt-6"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "auto" }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_v_card, null, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_v_card_title, {
                                                class: "justify-center",
                                                style: { "width": "100%", "text-align": "center" }
                                            }, {
                                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                                    _createTextVNode(" Aktuelle Marktgebiete ")
                                                ]), undefined, true),
                                                _: 1
                                            }),
                                            _createVNode(_component_v_card_actions, null, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_v_row, { justify: "center" }, {
                                                        default: _withCtx(() => [
                                                            _createVNode(_component_v_col, { cols: "auto" }, {
                                                                default: _withCtx(() => [
                                                                    _createVNode(_component_v_btn, {
                                                                        color: "primary",
                                                                        to: "/area",
                                                                        variant: "elevated"
                                                                    }, {
                                                                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                                                                            _createTextVNode(" Zum aktuellen Marktgebiet ")
                                                                        ]), undefined, true),
                                                                        _: 1
                                                                    })
                                                                ], undefined, true),
                                                                _: 1
                                                            }),
                                                            _createVNode(_component_v_col, { cols: "auto" }, {
                                                                default: _withCtx(() => [
                                                                    _createVNode(_component_v_btn, {
                                                                        color: "primary",
                                                                        onClick: resetAndGo,
                                                                        variant: "elevated"
                                                                    }, {
                                                                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                                                                            _createTextVNode(" Neues Marktgebiet anlegen ")
                                                                        ]), undefined, true),
                                                                        _: 1
                                                                    })
                                                                ], undefined, true),
                                                                _: 1
                                                            })
                                                        ], undefined, true),
                                                        _: 1
                                                    })
                                                ], undefined, true),
                                                _: 1
                                            })
                                        ], undefined, true),
                                        _: 1
                                    })
                                ], undefined, true),
                                _: 1
                            })
                        ], undefined, true),
                        _: 1
                    }),
                    _createVNode(_component_v_dialog, {
                        modelValue: showCopyDialog.value,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((showCopyDialog).value = $event)),
                        width: "500"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_v_card, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_v_form, {
                                        modelValue: copyForm.value,
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((copyForm).value = $event)),
                                        onSubmit: _withModifiers(copyMarketArea, ["prevent"])
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_v_card_title, {
                                                class: "headline bg-primary-lighten-2 white--text",
                                                innerHTML: 'Marktgebiet kopieren'
                                            }),
                                            _createVNode(_component_v_card_text, { class: "pt-6" }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_v_row, null, {
                                                        default: _withCtx(() => [
                                                            (marketAreaToCopy.value)
                                                                ? (_openBlock(), _createBlock(_component_v_col, {
                                                                    key: 0,
                                                                    cols: "12"
                                                                }, {
                                                                    default: _withCtx(() => [
                                                                        (_openBlock(), _createBlock(_component_v_text_field, {
                                                                            label: "Name *",
                                                                            variant: "outlined",
                                                                            readonly: copyingMarketArea.value,
                                                                            rules: [requiredNameRule],
                                                                            modelValue: marketAreaToCopy.value.title,
                                                                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((marketAreaToCopy.value.title) = $event)),
                                                                            key: marketAreaToCopyKey.value
                                                                        }, null, 8, ["readonly", "rules", "modelValue"]))
                                                                    ], undefined, true),
                                                                    _: 1
                                                                }))
                                                                : _createCommentVNode("", true)
                                                        ], undefined, true),
                                                        _: 1
                                                    })
                                                ], undefined, true),
                                                _: 1
                                            }),
                                            _createVNode(_component_v_divider),
                                            _createVNode(_component_v_card_actions, null, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_v_btn, {
                                                        onClick: _cache[3] || (_cache[3] = ($event) => (showCopyDialog.value = false)),
                                                        variant: "text"
                                                    }, {
                                                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                                                            _createTextVNode("Abbrechen")
                                                        ]), undefined, true),
                                                        _: 1
                                                    }),
                                                    _createVNode(_component_v_spacer),
                                                    _createVNode(_component_v_btn, {
                                                        type: "submit",
                                                        variant: "text",
                                                        color: "primary",
                                                        loading: copyingMarketArea.value,
                                                        disabled: !copyForm.value
                                                    }, {
                                                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                                                            _createTextVNode(" Kopieren ")
                                                        ]), undefined, true),
                                                        _: 1
                                                    }, 8, ["loading", "disabled"])
                                                ], undefined, true),
                                                _: 1
                                            })
                                        ], undefined, true),
                                        _: 1
                                    }, 8, ["modelValue"])
                                ], undefined, true),
                                _: 1
                            })
                        ], undefined, true),
                        _: 1
                    }, 8, ["modelValue"])
                ], undefined, true),
                _: 1
            }));
        };
    }
});
