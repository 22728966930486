import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import { de } from "vuetify/locale";
import "vuetify/styles";
const icons = {
    defaultSet: "mdi",
    aliases,
    sets: {
        mdi,
    },
};
const vuetify = createVuetify({
    icons,
    theme: {
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: "#004C4C",
                    secondary: "#424242",
                    accent: "#82B1FF",
                    error: "#FF5252",
                    info: "#2196F3",
                    success: "#4CAF50",
                    warning: "#FFC107",
                },
            },
        },
    },
    locale: {
        locale: "de",
        messages: { de },
        fallback: "de",
    },
});
export default vuetify;
