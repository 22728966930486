import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "box map-box" };
const _hoisted_2 = { class: "map-wrap" };
import { AreaType } from "@/api/types";
import { useRole } from "@/mixins/role";
import { getRandomMaterialColorFromText } from "@/store/helpers";
import { MarketAreaActions, MarketAreaGetters, MarketAreaState, } from "@/store/marketarea/types";
import maplibregl from "maplibre-gl";
import { watch } from "vue";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import MaplibreGeocoder from "@maplibre/maplibre-gl-geocoder";
import { MaplibreTerradrawControl } from "@watergis/maplibre-gl-terradraw";
import { booleanIntersects } from "@turf/boolean-intersects";
import "@watergis/maplibre-gl-terradraw/dist/maplibre-gl-terradraw.css";
import "@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css";
// ----------------------------------------- Const -----------------------------------------
export default /*@__PURE__*/ _defineComponent({
    __name: 'MapView',
    props: ["hidePriceCalculator", "showEvalAmount", "hideSubmarketList"],
    setup(__props) {
        const store = useStore();
        const MTK = globalThis.MTK;
        const GERMANY_CENTER = [10.4515, 51.1657];
        const { t } = useI18n();
        const { isAreaReadOnly } = useRole();
        const geocoderApi = {
            forwardGeocode: async (config) => {
                console.log("forwardGeocode", config);
                const features = [];
                let bbox = [];
                try {
                    const request = `https://nominatim.openstreetmap.org/search?q=${config.query}&accept-language=de&countrycodes=de&format=geojson&polygon_geojson=1&addressdetails=1`;
                    const response = await fetch(request);
                    const geojson = await response.json();
                    console.log("geojson", geojson);
                    bbox = geojson.features?.[0].bbox;
                    for (const feature of geojson.features) {
                        const center = [
                            feature.bbox[0] + (feature.bbox[2] - feature.bbox[0]) / 2,
                            feature.bbox[1] + (feature.bbox[3] - feature.bbox[1]) / 2,
                        ];
                        const point = {
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: center,
                            },
                            place_name: feature.properties.display_name,
                            properties: feature.properties,
                            text: feature.properties.display_name,
                            place_type: ["place"],
                            center,
                            bbox: feature.bbox,
                        };
                        features.push(point);
                    }
                }
                catch (e) {
                    console.error(`Failed to forwardGeocode with error: ${e}`);
                }
                return {
                    type: "FeatureCollection",
                    features,
                    bbox,
                };
            },
        };
        // ----------------------------------------- Props -----------------------------------------
        // ----------------------------------------- Data -----------------------------------------
        const MapGlInstance = ref();
        const MapMTKInstance = ref();
        const popup = ref();
        const MapElement = ref(null);
        const zoom = ref(10);
        const drawControl = ref();
        // ----------------------------------------- Computed -----------------------------------------
        const initialMarkedArea = computed(() => store.state.marketArea[MarketAreaState.initialMarkedArea]);
        const unselectedAreas = computed(() => store.getters[MarketAreaGetters.unselectedAreas]);
        const areasWithGroup = computed(() => store.getters[MarketAreaGetters.areasWithGroup]);
        const detailAreas = computed(() => store.state.marketArea[MarketAreaState.detailAreas]);
        const additionalVisibleAreas = computed(() => store.state.marketArea[MarketAreaState.additionalVisibleAreas]);
        const currentBounds = computed(() => store.state.marketArea.currentBounds);
        const showDetail = computed(() => store.state.marketArea.showDetail);
        const activeLayer = computed(() => store.state.marketArea.activeLayer);
        const currentMap = computed(() => store.state.marketArea.currentMap);
        const unselectedAreasGeometry = computed(() => {
            return {
                type: "FeatureCollection",
                features: unselectedAreas.value.map((tile) => {
                    return {
                        ...coordinatesForArea(tile),
                        type: "Feature",
                    };
                }),
            };
        });
        const selectedAreasGeometry = computed(() => {
            return {
                type: "FeatureCollection",
                features: areasWithGroup.value.map((tile) => {
                    return {
                        type: "Feature",
                        ...coordinatesForGroup(tile),
                    };
                }),
            };
        });
        const detailAreasGeometry = computed(() => {
            return {
                type: "FeatureCollection",
                features: detailAreas.value.map((tile) => {
                    return {
                        type: "Feature",
                        ...coordinatesForGroup(tile),
                    };
                }),
            };
        });
        const additionalVisibleAreaGeometry = computed(() => {
            return {
                type: "FeatureCollection",
                features: additionalVisibleAreas.value.map((tile) => {
                    return {
                        ...coordinatesForArea(tile),
                        type: "Feature",
                    };
                }),
            };
        });
        // ----------------------------------------- Methods -----------------------------------------
        const latLngToLngLat = (latLng) => {
            return [latLng[1], latLng[0]];
        };
        const resizeMap = () => {
            if (MapGlInstance.value) {
                setTimeout(() => {
                    MapGlInstance.value?.resize();
                }, 200);
            }
        };
        const updateMapBounds = (bounds) => {
            store.dispatch(MarketAreaActions.updateMapBounds, bounds);
        };
        const coordinatesForArea = (entry) => {
            if (!entry) {
                return null;
            }
            return { geometry: entry.geometry, properties: { entry } };
        };
        const coordinatesForGroup = (entry) => {
            if (!entry) {
                return null;
            }
            const color = entry.group.isDefault
                ? "#004C4C"
                : getRandomMaterialColorFromText(entry.group.id + "_" + entry.group.title);
            const colorHovered = "#bbff00";
            return {
                geometry: entry.area.geometry,
                properties: { entry, color, colorHovered },
            };
        };
        const toggleSelectedArea = (payload) => {
            return store.dispatch(MarketAreaActions.toggleSelectedArea, payload);
        };
        const addToSelectedArea = (payload) => {
            return store.dispatch(MarketAreaActions.addToSelectedArea, payload);
        };
        const fetchPriceStyle = async () => {
            const result = await fetch(`https://static.maptoolkit.net/styles/iib/offer_pricelay_sz8a.json?api_key=${process.env.VUE_APP_MTK_API_KEY}`).then((res) => res.json());
            return result;
        };
        const getMapStyle = (mapStyle) => {
            switch (mapStyle) {
                case "iib-wohnlagenkarte_kd7g":
                    return "iib-wohnlagenkarte_kd7g";
                case "iib-sw_style":
                    return "iib-sw_style";
                case "open-street-map":
                default:
                    return {
                        version: 8,
                        sources: {
                            osm: {
                                type: "raster",
                                tiles: ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"],
                                tileSize: 256,
                            },
                        },
                        sprite: "https://static.maptoolkit.net/sprites/iib",
                        glyphs: "https://vtile01.maptoolkit.net/fonts/{fontstack}/{range}.pbf",
                        layers: [
                            {
                                id: "osm",
                                type: "raster",
                                source: "osm", // This must match the source key above
                            },
                        ],
                    };
            }
        };
        const getTooltipContent = (text) => {
            const content = document.createElement("div");
            content.innerHTML = text;
            content.style.fontSize = "10px";
            content.style.padding = "0px";
            content.style.pointerEvents = "none";
            return content;
        };
        const getTooltipFromArea = (area) => {
            let tooltip = area.title;
            if (area.areaType === AreaType.kgs11 || area.areaType === AreaType.kgs14) {
                tooltip = area.licensePlate + " - " + tooltip;
            }
            tooltip = `${t(`areaType.abbreviation.${area.areaType}`)} - ${tooltip}`;
            return tooltip;
        };
        const showPopup = (tooltip, position) => {
            popup.value.setLngLat(position).setDOMContent(getTooltipContent(tooltip));
            popup.value.addTo(MapGlInstance.value);
        };
        const addSourcesAndLayers = async () => {
            const priceStyle = await fetchPriceStyle();
            MapGlInstance.value?.addSource("offer_prices", {
                type: "vector",
                url: priceStyle.sources["offer_prices"].url,
            });
            priceStyle.layers.forEach((layer) => {
                MapGlInstance.value.addLayer({
                    ...layer,
                    layout: {
                        ...layer.layout,
                        visibility: layer.id === "label_places" || layer.id === activeLayer.value
                            ? "visible"
                            : "none",
                    },
                });
            });
            MapGlInstance.value?.addSource("unselectedAreas", {
                type: "geojson",
                data: unselectedAreasGeometry.value,
            });
            MapGlInstance.value?.addSource("selectedAreas", {
                type: "geojson",
                data: selectedAreasGeometry.value,
            });
            MapGlInstance.value?.addSource("detailAreas", {
                type: "geojson",
                data: detailAreasGeometry.value,
            });
            MapGlInstance.value?.addSource("additionalVisibleAreas", {
                type: "geojson",
                data: additionalVisibleAreaGeometry.value,
            });
            MapGlInstance.value?.addLayer({
                id: "unselectedAreas",
                type: "line",
                source: "unselectedAreas",
                layout: {
                    visibility: showDetail.value ? "none" : "visible",
                },
                paint: {
                    "line-color": "#004C4C",
                    "line-width": 2,
                },
            });
            MapGlInstance.value?.addLayer({
                id: "unselectedAreasFill",
                type: "fill",
                source: "unselectedAreas",
                layout: {
                    visibility: showDetail.value ? "none" : "visible",
                },
                paint: {
                    "fill-color": "#004C4C",
                    "fill-opacity": 0,
                },
            });
            MapGlInstance.value?.addLayer({
                id: "selectedAreas",
                type: "line",
                source: "selectedAreas",
                layout: {
                    visibility: showDetail.value ? "none" : "visible",
                },
                paint: {
                    "line-color": [
                        "case",
                        [
                            "==",
                            ["get", "hovered", ["get", "area", ["get", "entry", ["properties"]]]],
                            true,
                        ],
                        ["get", "colorHovered"],
                        ["get", "color"],
                    ],
                    "line-width": 4,
                },
            });
            MapGlInstance.value?.addLayer({
                id: "selectedAreasFill",
                type: "fill",
                source: "selectedAreas",
                paint: {
                    "fill-color": [
                        "case",
                        [
                            "==",
                            ["get", "hovered", ["get", "area", ["get", "entry", ["properties"]]]],
                            true,
                        ],
                        ["get", "colorHovered"],
                        ["get", "color"],
                    ],
                    "fill-opacity": 0.2,
                },
            });
            MapGlInstance.value?.addLayer({
                id: "detailAreas",
                type: "line",
                source: "detailAreas",
                layout: {
                    visibility: showDetail.value ? "visible" : "none",
                },
                paint: {
                    "line-color": [
                        "case",
                        [
                            "==",
                            ["get", "hovered", ["get", "area", ["get", "entry", ["properties"]]]],
                            true,
                        ],
                        ["get", "colorHovered"],
                        ["get", "color"],
                    ],
                    "line-width": 4,
                },
            });
            MapGlInstance.value?.addLayer({
                id: "additionalVisibleAreas",
                type: "line",
                source: "additionalVisibleAreas",
                paint: {
                    "line-color": "red",
                    "line-width": 4,
                },
            });
            MapGlInstance.value?.addLayer({
                id: "additionalVisibleAreasFill",
                type: "fill",
                source: "additionalVisibleAreas",
                paint: {
                    "fill-color": "red",
                    "fill-opacity": 0.2,
                },
            });
            MapGlInstance.value?.moveLayer("additionalVisibleAreas");
            MapGlInstance.value?.moveLayer("additionalVisibleAreasFill");
            MapGlInstance.value?.moveLayer("unselectedAreas");
            MapGlInstance.value?.moveLayer("unselectedAreasFill");
            MapGlInstance.value?.moveLayer("selectedAreas");
            MapGlInstance.value?.moveLayer("selectedAreasFill");
            MapGlInstance.value?.moveLayer("detailAreas");
            MapGlInstance.value?.moveLayer("label_places");
            MapGlInstance.value?.moveLayer("label_house_buy");
            MapGlInstance.value?.moveLayer("label_appartment_buy");
            MapGlInstance.value?.moveLayer("label_house_rent");
            MapGlInstance.value?.moveLayer("label_appartment_rent");
        };
        const addMouseEvents = () => {
            if (!isAreaReadOnly(initialMarkedArea.value)) {
                MapGlInstance.value?.on("click", "unselectedAreasFill", (e) => {
                    const features = e.features;
                    toggleSelectedArea({
                        area: JSON.parse(features?.[0].properties.entry),
                        delete: false,
                    });
                });
                MapGlInstance.value?.on("click", "selectedAreasFill", (e) => {
                    const features = e.features;
                    toggleSelectedArea({
                        area: JSON.parse(features?.[features.length - 1].properties.entry)
                            .area,
                        delete: false,
                    });
                });
            }
            MapGlInstance.value?.on("mousemove", "unselectedAreasFill", (e) => {
                const feature = e.features?.[0];
                const area = JSON.parse(feature.properties.entry);
                let tooltip = area.title;
                if (area.areaType === AreaType.kgs11 || area.areaType === AreaType.kgs14) {
                    tooltip = area.licensePlate + " - " + tooltip;
                }
                tooltip = `${t(`areaType.abbreviation.${area.areaType}`)} - ${area.kgs} - ${tooltip}`;
                showPopup(tooltip, area.centroid?.coordinates || e.lngLat);
            });
            MapGlInstance.value?.on("mousemove", "selectedAreasFill", (e) => {
                const feature = e.features?.[e.features.length - 1];
                const area = JSON.parse(feature.properties.entry).area;
                const tooltip = getTooltipFromArea(area);
                showPopup(tooltip, area.centroid?.coordinates || e.lngLat);
            });
            MapGlInstance.value?.on("mousemove", "additionalVisibleAreasFill", (e) => {
                const feature = e.features?.[0];
                const area = JSON.parse(feature.properties.entry);
                const tooltip = getTooltipFromArea(area);
                showPopup(tooltip, area.centroid?.coordinates || e.lngLat);
            });
            MapGlInstance.value?.on("mouseleave", "unselectedAreasFill", () => {
                popup.value.remove();
            });
            MapGlInstance.value?.on("mouseleave", "selectedAreasFill", () => {
                popup.value.remove();
            });
            MapGlInstance.value?.on("mouseleave", "additionalVisibleAreasFill", () => {
                popup.value.remove();
            });
            MapGlInstance.value?.on("mouseleave", () => {
                popup.value.remove();
            });
        };
        const onDrawFinish = (featureId) => {
            if (!drawControl.value)
                return;
            const drawInstance = drawControl.value.getTerraDrawInstance();
            const feature = drawControl.value.getFeatures().features[0];
            const intersectingAreas = unselectedAreasGeometry.value.features
                .filter((unselectedFeature) => {
                const intersects = booleanIntersects(feature, unselectedFeature);
                return intersects;
            })
                .map((feature) => feature.properties.entry);
            addToSelectedArea({ areas: intersectingAreas });
            drawInstance.removeFeatures([featureId]);
            drawControl.value.deactivate();
        };
        const hideLayer = (layer) => {
            MapGlInstance.value?.setLayoutProperty(layer, "visibility", "none");
        };
        const showLayer = (layer) => {
            MapGlInstance.value?.setLayoutProperty(layer, "visibility", "visible");
        };
        // ----------------------------------------- Watchers -----------------------------------------
        watch(() => [__props.hidePriceCalculator, __props.hideSubmarketList, __props.showEvalAmount], () => {
            resizeMap();
        });
        watch(unselectedAreasGeometry, (updated) => {
            if (MapGlInstance.value) {
                MapGlInstance.value?.getSource("unselectedAreas")?.setData(updated);
            }
        });
        watch(selectedAreasGeometry, (updated) => {
            if (MapGlInstance.value) {
                MapGlInstance.value?.getSource("selectedAreas")?.setData(updated);
            }
        });
        watch(detailAreasGeometry, (updated) => {
            if (MapGlInstance.value) {
                MapGlInstance.value?.getSource("detailAreas")?.setData(updated);
            }
        });
        watch(additionalVisibleAreaGeometry, (updated) => {
            if (MapGlInstance.value) {
                MapGlInstance.value?.getSource("additionalVisibleAreas")?.setData(updated);
            }
        });
        watch(showDetail, (updated) => {
            if (MapGlInstance.value) {
                if (updated) {
                    hideLayer("unselectedAreas");
                    hideLayer("unselectedAreasFill");
                    hideLayer("selectedAreas");
                    showLayer("detailAreas");
                }
                else {
                    showLayer("unselectedAreas");
                    showLayer("unselectedAreasFill");
                    showLayer("selectedAreas");
                    hideLayer("detailAreas");
                }
            }
        });
        watch(activeLayer, (updated, prev) => {
            if (MapGlInstance.value) {
                if (updated === prev)
                    return;
                if (updated !== "pricelayer_none") {
                    showLayer(updated);
                }
                if (prev !== "pricelayer_none") {
                    hideLayer(prev);
                }
            }
        });
        watch(currentMap, (updated) => {
            if (MapMTKInstance.value) {
                MapMTKInstance.value.mapType = getMapStyle(updated);
            }
        });
        // ----------------------------------------- Lifecycle -----------------------------------------
        onMounted(() => {
            if (MapElement.value != undefined) {
                let center = GERMANY_CENTER;
                if (initialMarkedArea.value?.boundingBox) {
                    const bounds = initialMarkedArea.value.boundingBox.map(latLngToLngLat);
                    center = [
                        (bounds[0][0] + bounds[1][0]) / 2,
                        (bounds[0][1] + bounds[1][1]) / 2,
                    ];
                }
                else if (currentBounds.value) {
                    let bounds = [
                        [currentBounds.value[0], currentBounds.value[1]],
                        [currentBounds.value[2], currentBounds.value[3]],
                    ];
                    bounds = bounds.map(latLngToLngLat);
                    center = [
                        (bounds[0][0] + bounds[1][0]) / 2,
                        (bounds[0][1] + bounds[1][1]) / 2,
                    ];
                }
                drawControl.value = new MaplibreTerradrawControl({
                    modes: ["freehand"],
                    open: true,
                });
                MTK.init({ apiKey: process.env.VUE_APP_MTK_API_KEY }).createMap(MapElement.value, {
                    map: {
                        location: { center, zoom: zoom.value, minZoom: 8 },
                        mapType: getMapStyle(currentMap.value),
                        controls: [
                            [
                                new maplibregl.NavigationControl({ showCompass: false }),
                                "top-left",
                            ],
                            [
                                new MaplibreGeocoder(geocoderApi, {
                                    marker: false,
                                    countries: "de",
                                    placeholder: "Suche nach Adresse",
                                    showResultsWhileTyping: true,
                                    minLength: 3,
                                    collapsed: true,
                                    flyTo: {
                                        animate: false,
                                        duration: 0,
                                    },
                                }),
                                "top-left",
                            ],
                            [drawControl.value, "top-left"],
                        ],
                    },
                }, async (LoadedMapInstance) => {
                    MapMTKInstance.value = LoadedMapInstance;
                    MapGlInstance.value = MapMTKInstance.value.gl;
                    popup.value = new maplibregl.Popup({
                        closeButton: false,
                        closeOnClick: false,
                    });
                    MapGlInstance.value.on("moveend", (e) => {
                        const bounds = MapGlInstance.value.getBounds();
                        updateMapBounds(bounds);
                    });
                    MapGlInstance.value._locale = {
                        ...MapGlInstance.value._locale,
                        "Marker.Title": "Marker",
                    };
                    MapGlInstance.value.on("zoomend", () => {
                        zoom.value = MapGlInstance.value.getZoom();
                    });
                    addSourcesAndLayers();
                    MapMTKInstance.value.on("maptypechanged", addSourcesAndLayers);
                    addMouseEvents();
                    drawControl.value.getTerraDrawInstance().on("finish", onDrawFinish);
                });
            }
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", {
                        class: "map-element",
                        ref_key: "MapElement",
                        ref: MapElement
                    }, null, 512)
                ])
            ]));
        };
    }
});
