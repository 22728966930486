import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "box map-box" };
const _hoisted_2 = { class: "map-wrap" };
import { calculateMarketAreaIndices, getRandomMaterialColorFromText, } from "@/store/helpers";
import { AreaTypeMapWeight, MarketAreaActions, MarketAreaGetters, MarketAreaState, } from "@/store/marketarea/types";
import maplibregl from "maplibre-gl";
import { watch } from "vue";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
// ----------------------------------------- Const -----------------------------------------
export default /*@__PURE__*/ _defineComponent({
    __name: 'PrintMap',
    props: ["loadingInitialArea"],
    setup(__props) {
        const store = useStore();
        const MTK = globalThis.MTK;
        const GERMANY_CENTER = [10.4515, 51.1657];
        const boundsPadding = [2.5, 2.5];
        const boundsPaddingBottomRight = [2.5, 2.5];
        // ----------------------------------------- Props -----------------------------------------
        // ----------------------------------------- Data -----------------------------------------
        const MapGlInstance = ref();
        const MapMTKInstance = ref();
        const MapElement = ref(null);
        const zoom = ref(12);
        const mapReady = ref(false);
        // ----------------------------------------- Computed -----------------------------------------
        const initialMarkedArea = computed(() => store.state.marketArea[MarketAreaState.initialMarkedArea]);
        const areaGroupIndices = computed(() => {
            return initialMarkedArea.value.marketAreaGroups
                ?.map((group, groupIdx) => {
                return group.selectedAreas?.map((area, areaIdx) => groupIndex(initialMarkedArea.value)(groupIdx, areaIdx, area.kgs));
            })
                .flat();
        });
        const areasWithGroup = computed(() => store.getters[MarketAreaGetters.areasWithGroup]);
        const currentBounds = computed(() => store.state.marketArea.currentBounds);
        const selectedAreasGeometry = computed(() => {
            return {
                type: "FeatureCollection",
                features: areasWithGroup.value.map((tile) => {
                    const groupedIndex = areaGroupIndices.value.find(({ index, kgs }) => kgs === tile.area.kgs)
                        ?.index || "";
                    return {
                        type: "Feature",
                        ...coordinatesForGroup(tile, groupedIndex),
                    };
                }),
            };
        });
        // ----------------------------------------- Methods -----------------------------------------
        const latLngToLngLat = (latLng) => {
            return [latLng[1], latLng[0]];
        };
        const updateMapBounds = (bounds) => {
            store.dispatch(MarketAreaActions.updateMapBounds, bounds);
        };
        const groupIndex = (markedAreas) => (groupIdx, areaIdx, kgs) => {
            return calculateMarketAreaIndices(markedAreas, groupIdx, areaIdx, kgs);
        };
        const coordinatesForGroup = (entry, groupedIndex) => {
            if (!entry) {
                return null;
            }
            const color = entry.group.isDefault
                ? "#990033"
                : getRandomMaterialColorFromText(entry.group.id + "_" + entry.group.title);
            const lineWeight = AreaTypeMapWeight[entry.area.areaType] || 1;
            return {
                geometry: entry.area.geometry,
                properties: { entry, color, groupedIndex, lineWeight },
            };
        };
        // ----------------------------------------- Watchers -----------------------------------------
        watch(selectedAreasGeometry, (updated) => {
            if (MapGlInstance.value) {
                MapGlInstance.value?.getSource("selectedAreas")?.setData(updated);
            }
        });
        watch(mapReady, (updated, prev) => {
            if (updated && !prev) {
                setTimeout(() => {
                    window.print();
                }, 1000);
            }
        });
        // ----------------------------------------- Lifecycle -----------------------------------------
        onMounted(() => {
            if (__props.loadingInitialArea) {
                return;
            }
            document.body.style.width = "21cm";
            document.body.style.height = "60.7cm";
            if (MapElement.value != undefined) {
                let center = GERMANY_CENTER;
                if (initialMarkedArea.value?.boundingBox) {
                    const bounds = initialMarkedArea.value.boundingBox.map(latLngToLngLat);
                    center = [
                        (bounds[0][0] + bounds[1][0]) / 2,
                        (bounds[0][1] + bounds[1][1]) / 2,
                    ];
                }
                MTK.init({ apiKey: process.env.VUE_APP_MTK_API_KEY }).createMap(MapElement.value, {
                    map: {
                        location: { center, zoom: zoom.value },
                        mapType: {
                            name: "mtk-plain",
                            version: 8,
                            sprite: "https://static.maptoolkit.net/sprites/iib",
                            glyphs: "https://static.maptoolkit.net/fonts/{fontstack}/{range}.pbf",
                            sources: {
                                residential: {
                                    tileSize: 256,
                                    tiles: [
                                        "https://alias.maptoolkit.net/iib/wohnlagenkarte/{z}/{x}/{y}.png?api_key=" +
                                            process.env.VUE_APP_MTK_API_KEY,
                                    ],
                                    type: "raster",
                                },
                            },
                            layers: [
                                {
                                    id: "plain",
                                    source: "residential",
                                    type: "raster",
                                    minzoom: 14,
                                    maxzoom: 18,
                                    layout: { visibility: "none" },
                                },
                            ],
                        },
                        controls: [
                            [
                                new maplibregl.NavigationControl({ showCompass: false }),
                                "top-left",
                            ],
                        ],
                    },
                }, async (LoadedMapInstance) => {
                    MapMTKInstance.value = LoadedMapInstance;
                    MapGlInstance.value = MapMTKInstance.value.gl;
                    MapGlInstance.value.on("moveend", (e) => {
                        const bounds = MapGlInstance.value.getBounds();
                        updateMapBounds(bounds);
                    });
                    // extend locale
                    MapGlInstance.value._locale = {
                        ...MapGlInstance.value._locale,
                        "Marker.Title": "Marker",
                    };
                    // add areas layer
                    MapGlInstance.value?.addSource("selectedAreas", {
                        type: "geojson",
                        data: selectedAreasGeometry.value,
                    });
                    MapGlInstance.value?.addLayer({
                        id: "selectedAreas",
                        type: "line",
                        source: "selectedAreas",
                        layout: {
                            visibility: "visible",
                        },
                        paint: {
                            "line-color": {
                                type: "identity",
                                property: "color",
                            },
                            "line-width": {
                                type: "identity",
                                property: "lineWeight",
                            },
                        },
                    });
                    MapGlInstance.value?.addLayer({
                        id: "selectedAreasFill",
                        type: "fill",
                        source: "selectedAreas",
                        paint: {
                            "fill-color": "#A5A5A5",
                            "fill-opacity": 0.7,
                        },
                    });
                    MapGlInstance.value.addLayer({
                        id: "areaIndexes",
                        type: "symbol",
                        source: "selectedAreas",
                        layout: {
                            "text-field": "{groupedIndex}",
                            "text-size": 16,
                            "text-font": ["Open Sans Regular"],
                            "text-anchor": "center",
                            "text-allow-overlap": true,
                            "text-ignore-placement": true,
                            "text-justify": "center",
                            "text-offset": [0, 0],
                            "text-rotate": 0,
                            "text-variable-anchor": ["top", "bottom", "left", "right"],
                        },
                        paint: {
                            "text-color": "#004C4C",
                            "text-halo-width": 2,
                        },
                    });
                    MapGlInstance.value?.moveLayer("selectedAreasFill");
                    MapGlInstance.value?.moveLayer("selectedAreas");
                    MapGlInstance.value?.moveLayer("areaIndexes");
                    if (initialMarkedArea.value?.boundingBox) {
                        MapGlInstance.value.fitBounds(initialMarkedArea.value.boundingBox.map(latLngToLngLat), {
                            duration: 0,
                            animate: false,
                            padding: {
                                top: boundsPadding[1],
                                bottom: boundsPaddingBottomRight[1],
                                left: boundsPadding[0],
                                right: boundsPaddingBottomRight[0],
                            },
                        });
                    }
                    else {
                        const initialBounds = currentBounds.value
                            ? new maplibregl.LngLatBounds([
                                [currentBounds.value[0], currentBounds.value[1]],
                                [currentBounds.value[2], currentBounds.value[3]],
                            ])
                            : new maplibregl.LngLatBounds([51.133481, 10.018343]);
                        MapGlInstance.value.fitBounds(initialBounds, {
                            duration: 0,
                            animate: false,
                        });
                    }
                    MapGlInstance.value.once("idle", () => {
                        mapReady.value = true;
                    });
                });
            }
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", {
                        class: "map-element",
                        ref_key: "MapElement",
                        ref: MapElement
                    }, null, 512)
                ])
            ]));
        };
    }
});
