import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import MarketAreaDashboard from "./MarketAreaDashboard.vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'Layout',
    setup(__props) {
        return (_ctx, _cache) => {
            const _component_app_bar = _resolveComponent("app-bar");
            const _component_v_container = _resolveComponent("v-container");
            const _component_v_main = _resolveComponent("v-main");
            const _component_v_app = _resolveComponent("v-app");
            return (_openBlock(), _createBlock(_component_v_app, { style: { "height": "100vh", "width": "100vw" } }, {
                default: _withCtx(() => [
                    _createVNode(_component_app_bar),
                    _createVNode(_component_v_main, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_v_container, { class: "pt-12" }, {
                                default: _withCtx(() => [
                                    _createVNode(MarketAreaDashboard)
                                ], undefined, true),
                                _: 1
                            })
                        ], undefined, true),
                        _: 1
                    })
                ], undefined, true),
                _: 1
            }));
        };
    }
});
