import { defineComponent as _defineComponent } from 'vue';
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { getCustomerById, getCustomers } from "../../api/resources";
import { AreaType } from "../../api/types";
import { MarketAreaActions, MarketAreaState, } from "../../store/marketarea/types";
import { timeout } from "../../store/helpers";
import { useRole } from "@/mixins/role";
import { useAuth0 } from "@auth0/auth0-vue";
import { computed } from "vue";
import { jwtDecode } from "jwt-decode";
export default /*@__PURE__*/ _defineComponent({
    __name: 'SaveMarketAreaDialog',
    setup(__props) {
        const store = useStore();
        const { isAdmin } = useRole();
        const { getAccessTokenSilently } = useAuth0();
        const dialog = ref(false);
        const loading = ref(false);
        const selectedCustomer = ref(null);
        const title = ref("");
        const items = ref([]);
        const isLoading = ref(false);
        const search = ref("");
        selectedCustomer.value;
        const initialMarkedArea = computed(() => store.state.marketArea[MarketAreaState.initialMarkedArea]);
        const saveMarketArea = (payload) => {
            return store.dispatch(MarketAreaActions.saveMarketArea, payload);
        };
        const initDialogValues = async () => {
            if (initialMarkedArea.value) {
                const customer = (await getCustomerById(initialMarkedArea.value.customerId)).data;
                selectedCustomer.value = customer;
                search.value = customer.companyName;
                title.value = initialMarkedArea.value.title;
            }
        };
        watch(() => search.value, async () => {
            if (isAdmin.value) {
                if (isLoading.value)
                    return;
                isLoading.value = true;
                try {
                    items.value = (await getCustomers(search.value)).data.items;
                }
                catch (error) {
                    console.error("failed to get customers " + error);
                    items.value = [selectedCustomer.value];
                }
                finally {
                    isLoading.value = false;
                }
            }
        });
        const save = async () => {
            loading.value = true;
            try {
                await saveMarketArea({
                    id: initialMarkedArea.value?.id,
                    marketArea: {
                        title: title.value,
                        customerId: selectedCustomer.value.id,
                        customerName: selectedCustomer.value.companyName,
                        marketAreaGroups: [],
                        activeArea: AreaType.kgs2, // will be set in action
                    },
                });
                await timeout(200);
            }
            finally {
                loading.value = false;
                dialog.value = false;
            }
        };
        onMounted(async () => {
            if (!isAdmin.value) {
                const token = await getAccessTokenSilently();
                const decodedToken = jwtDecode(token);
                selectedCustomer.value = (await getCustomerById("" + decodedToken?.["https://iib-it.de/customer"])).data;
                items.value.push(selectedCustomer.value);
            }
            await initDialogValues();
        });
        watch(initialMarkedArea, initDialogValues);
        return (_ctx, _cache) => {
            const _component_v_btn = _resolveComponent("v-btn");
            const _component_v_card_title = _resolveComponent("v-card-title");
            const _component_v_text_field = _resolveComponent("v-text-field");
            const _component_v_col = _resolveComponent("v-col");
            const _component_v_row = _resolveComponent("v-row");
            const _component_v_autocomplete = _resolveComponent("v-autocomplete");
            const _component_v_card_text = _resolveComponent("v-card-text");
            const _component_v_divider = _resolveComponent("v-divider");
            const _component_v_spacer = _resolveComponent("v-spacer");
            const _component_v_card_actions = _resolveComponent("v-card-actions");
            const _component_v_card = _resolveComponent("v-card");
            const _component_v_dialog = _resolveComponent("v-dialog");
            return (_openBlock(), _createBlock(_component_v_dialog, {
                modelValue: dialog.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((dialog).value = $event)),
                width: "500"
            }, {
                activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_btn, _mergeProps(props, { color: "primary" }), {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode("Speichern")
                        ]), undefined, true),
                        _: 2
                    }, 1040)
                ]),
                default: _withCtx(() => [
                    _createVNode(_component_v_card, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_v_card_title, {
                                class: "headline primary lighten-2 white--text",
                                innerHTML: initialMarkedArea.value && initialMarkedArea.value.id
                                    ? 'Marktgebiet speichern'
                                    : 'Neues Marktgebiet anlegen'
                            }, null, 8, ["innerHTML"]),
                            _createVNode(_component_v_card_text, { class: "pt-6" }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_v_row, null, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_v_col, { cols: "12" }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_v_text_field, {
                                                        label: "Name *",
                                                        variant: "outlined",
                                                        modelValue: title.value,
                                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((title).value = $event))
                                                    }, null, 8, ["modelValue"])
                                                ], undefined, true),
                                                _: 1
                                            })
                                        ], undefined, true),
                                        _: 1
                                    }),
                                    _createVNode(_component_v_row, { "no-gutters": "" }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_v_col, { cols: "12" }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_v_autocomplete, {
                                                        modelValue: selectedCustomer.value,
                                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((selectedCustomer).value = $event)),
                                                        items: items.value,
                                                        loading: isLoading.value,
                                                        search: search.value,
                                                        "onUpdate:search": _cache[2] || (_cache[2] = ($event) => (search.value = $event)),
                                                        "append-icon": "mdi-search",
                                                        "return-object": "",
                                                        variant: "outlined",
                                                        label: "Kunde *",
                                                        "item-value": "id",
                                                        "item-title": "companyName",
                                                        filter: () => true,
                                                        disabled: !_unref(isAdmin),
                                                        clearable: "",
                                                        "hide-no-data": ""
                                                    }, null, 8, ["modelValue", "items", "loading", "search", "disabled"])
                                                ], undefined, true),
                                                _: 1
                                            })
                                        ], undefined, true),
                                        _: 1
                                    })
                                ], undefined, true),
                                _: 1
                            }),
                            _createVNode(_component_v_divider),
                            _createVNode(_component_v_card_actions, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_v_btn, {
                                        onClick: _cache[3] || (_cache[3] = ($event) => (dialog.value = false)),
                                        variant: "text"
                                    }, {
                                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                                            _createTextVNode("Abbrechen")
                                        ]), undefined, true),
                                        _: 1
                                    }),
                                    _createVNode(_component_v_spacer),
                                    _createVNode(_component_v_btn, {
                                        onClick: save,
                                        variant: "text",
                                        color: "primary",
                                        loading: loading.value,
                                        disabled: !selectedCustomer.value
                                    }, {
                                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                                            _createTextVNode(" Speichern ")
                                        ]), undefined, true),
                                        _: 1
                                    }, 8, ["loading", "disabled"])
                                ], undefined, true),
                                _: 1
                            })
                        ], undefined, true),
                        _: 1
                    })
                ], undefined, true),
                _: 1
            }, 8, ["modelValue"]));
        };
    }
});
