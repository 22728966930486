import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";
import { MarketAreaState } from "../../store/marketarea/types";
export default /*@__PURE__*/ _defineComponent({
    __name: 'EvaluationAmountRenderer',
    props: ["value"],
    setup(__props) {
        const store = useStore();
        // Accessing the showTraffic state from Vuex
        const showTraffic = computed(() => store.state.marketArea[MarketAreaState.showTraffic]);
        // Computed property for class based on the value
        const classForValue = computed(() => {
            if (__props.value === 10) {
                return "yellow";
            }
            if (__props.value > 10) {
                return "green";
            }
            return "red";
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("span", null, [
                (!showTraffic.value)
                    ? _renderSlot(_ctx.$slots, "default", { key: 0 })
                    : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        style: { "width": "14px", "height": "14px" },
                        class: _normalizeClass(`${classForValue.value} float-right mr-1`)
                    }, null, 2))
            ]));
        };
    }
});
