import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
export default {
    install(app) {
        const requireComponent = require.context("../components/base", true, /\.vue$/);
        requireComponent.keys().forEach((fileName) => {
            const componentConfig = requireComponent(fileName);
            const componentName = upperFirst(camelCase(fileName.replace(/^\.\//, "").replace(/\.\w+$/, "")));
            app.component(componentName, componentConfig.default || componentConfig);
        });
    },
};
