import { marketAreaResource } from "../../api/resources";
import { set } from "../helpers";
export var MarketAreaOverviewActions;
(function (MarketAreaOverviewActions) {
    MarketAreaOverviewActions["load"] = "load";
    MarketAreaOverviewActions["loadById"] = "loadById";
    MarketAreaOverviewActions["excelExport"] = "excelExport";
})(MarketAreaOverviewActions || (MarketAreaOverviewActions = {}));
export var MarketAreaOverviewMutations;
(function (MarketAreaOverviewMutations) {
    MarketAreaOverviewMutations["setMarketAreas"] = "setMarketAreas";
})(MarketAreaOverviewMutations || (MarketAreaOverviewMutations = {}));
export const marketAreaOverviewModule = {
    // namespaced: true,
    state: {
        marketAreas: [],
    },
    mutations: {
        [MarketAreaOverviewMutations.setMarketAreas]: set("marketAreas"),
    },
    actions: {
        [MarketAreaOverviewActions.load]: async ({ commit }, payload) => {
            const marketAreas = (await marketAreaResource.get(`?page=${payload.page}&limit=${payload.items}&sort=${payload.sort}&sortBy=${payload.sortBy || "id"}&searchQuery=${payload.searchQuery}`, {
                signal: payload.signal,
            })).data;
            commit(MarketAreaOverviewMutations.setMarketAreas, marketAreas.items);
            return marketAreas;
        },
        async [MarketAreaOverviewActions.loadById]({ commit }, id) {
            const marketAreas = (await marketAreaResource.get(`/${id}`)).data;
        },
        async [MarketAreaOverviewActions.excelExport](_, id) {
            await marketAreaResource
                .get(`/${id}/export`, { responseType: "blob" })
                .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "marktgebiet.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        },
    },
};
