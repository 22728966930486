import axios from "axios";
import { authMiddleWare } from "./auth-middleware";
const BASE_URL = `${process.env.VUE_APP_BASE_URL || ""}/api/v1`;
export const areasResource = axios.create({
    baseURL: `${BASE_URL}/geometry`,
    headers: { "Content-Type": "application/json;charset=utf-8" },
});
export const getAreas = (kgsType, previousBounds, bounds) => areasResource.get("/areas", {
    params: { areaType: kgsType, bounds, previousBounds },
});
export const getArea = (kgsType, kgs) => areasResource.get(`/area/${kgs}`, {
    params: { areaType: kgsType },
});
export const getAreaList = (areasList) => areasResource.post(`/area`, areasList);
export const priceResource = axios.create({
    baseURL: `${BASE_URL}/prices`,
    headers: { "Content-Type": "application/json;charset=utf-8" },
});
export const getPrices = () => priceResource.get("");
export const marketAreaResource = axios.create({
    baseURL: `${BASE_URL}/marketareas`,
    headers: { "Content-Type": "application/json;charset=utf-8" },
});
marketAreaResource.interceptors.request.use(authMiddleWare);
export const createMarketArea = (marketArea) => marketAreaResource.post("", marketArea);
export const updateMarketArea = (id, marketArea) => marketAreaResource.patch("" + id, marketArea);
export const adminCustomerResource = axios.create({
    baseURL: `https://admin-api.iib-it.de/1.0/customers/customers`,
    headers: {
        "Content-Type": "application/json;charset=utf-8",
    },
});
export const customerResource = axios.create({
    baseURL: `https://api.iib-it.de/1.0/customers/customers`,
    headers: {
        "Content-Type": "application/json;charset=utf-8",
    },
});
adminCustomerResource.interceptors.request.use(authMiddleWare);
export const getCustomerById = async (id) => await customerResource.get(`/${id}`);
export const getCustomers = async (query) => await adminCustomerResource.get(`?query=${query}`);
