import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "summary-row" };
const _hoisted_2 = { class: "text-end mt-1" };
const _hoisted_3 = { class: "font-weight-bold" };
import { computed } from "vue";
import { useStore } from "vuex";
import { currencyFormatter } from "../../plugins/formatter";
import { MarketAreaGetters } from "../../store/marketarea/types";
export default /*@__PURE__*/ _defineComponent({
    __name: 'MarketPricing',
    setup(__props) {
        const store = useStore();
        const marketAreaGroups = computed(() => store.getters[MarketAreaGetters.marketAreaGroups]);
        const prices = computed(() => store.state.prices);
        const formatCurrency = (price) => {
            return currencyFormatter.format(price);
        };
        const headers = [
            { title: "Pos.", value: "pos", sortable: false, align: "start" },
            {
                title: "Stück",
                value: "amount",
                sortable: false,
                align: "end",
            },
            {
                title: "Preis (netto)",
                value: "price",
                sortable: false,
                align: "end",
            },
        ];
        const defaultGroup = computed(() => {
            return marketAreaGroups.value.find((group) => group.isDefault);
        });
        const amount = computed(() => {
            return (marketAreaGroups.value.filter((group) => !group.isDefault && group.selectedAreas?.length).length + defaultGroup.value?.selectedAreas.length || 0);
        });
        const items = computed(() => {
            return [
                {
                    pos: "Grundgebühr",
                    amount: 1,
                    price: prices.value.basePrice,
                },
                {
                    pos: "Teilmärkte",
                    amount: amount.value,
                    price: prices.value.pricePerUnit * amount.value,
                },
            ];
        });
        const totalPrice = computed(() => {
            return prices.value.basePrice + amount.value * prices.value.pricePerUnit;
        });
        return (_ctx, _cache) => {
            const _component_v_data_table = _resolveComponent("v-data-table");
            const _component_v_card_text = _resolveComponent("v-card-text");
            const _component_v_card = _resolveComponent("v-card");
            return (_openBlock(), _createBlock(_component_v_card, { elevation: "0" }, {
                default: _withCtx(() => [
                    _createVNode(_component_v_card_text, { class: "px-1 py-2" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_v_data_table, {
                                headers: headers,
                                items: items.value,
                                "disable-pagination": "",
                                "hide-default-footer": "",
                                density: "compact",
                                id: "market-pricing__table"
                            }, {
                                "item.price": _withCtx(({ item }) => [
                                    _createTextVNode(_toDisplayString(formatCurrency(item.price)), 1)
                                ]),
                                "body.append": _withCtx(() => [
                                    _createElementVNode("tr", _hoisted_1, [
                                        _cache[0] || (_cache[0] = _createElementVNode("td", { colspan: "2" }, [
                                            _createElementVNode("span", { class: "font-weight-bold" }, "Summe")
                                        ], -1)),
                                        _createElementVNode("td", _hoisted_2, [
                                            _createElementVNode("span", _hoisted_3, _toDisplayString(formatCurrency(totalPrice.value)), 1)
                                        ])
                                    ])
                                ]),
                                _: 1
                            }, 8, ["items"])
                        ], undefined, true),
                        _: 1
                    })
                ], undefined, true),
                _: 1
            }));
        };
    }
});
