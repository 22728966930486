import authClient from "./plugins/auth";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import BaseComponents from "./plugins/base";
import Formatter from "./plugins/formatter";
import i18n from "./plugins/i18n";
import VuetifyUseDialog from "vuetify-use-dialog";
import "./plugins/validate";
import { app } from "./plugins/app";
app
    .use(router)
    .use(authClient)
    .use(BaseComponents)
    .use(Formatter)
    .use(vuetify)
    .use(VuetifyUseDialog)
    .use(store)
    .use(i18n)
    .mount("#app");
