import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = { class: "d-flex grow flex-wrap" };
const _hoisted_2 = ["textContent"];
const _hoisted_3 = ["textContent"];
const _hoisted_4 = {
    key: 2,
    class: "ml-6"
};
const _hoisted_5 = {
    key: 4,
    class: "ml-4"
};
const _hoisted_6 = ["textContent"];
import { useSlots, computed } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'MaterialCard',
    props: [
        "avatar",
        "color",
        "hoverReveal",
        "icon",
        "image",
        "inline",
        "text",
        "title",
    ],
    setup(__props) {
        const componentSlots = useSlots();
        const hasHeading = computed(() => {
            return Boolean(componentSlots?.heading || __props.title || __props.icon);
        });
        // Check if the card has an alternative heading
        const hasAltHeading = computed(() => {
            return Boolean(componentSlots?.heading || (__props.title && __props.icon));
        });
        // Compute classes dynamically
        const classes = computed(() => {
            return {
                "v-card--material--has-heading": hasHeading.value,
                "v-card--material--hover-reveal": __props.hoverReveal,
            };
        });
        return (_ctx, _cache) => {
            const _component_v_img = _resolveComponent("v-img");
            const _component_v_avatar = _resolveComponent("v-avatar");
            const _component_v_icon = _resolveComponent("v-icon");
            const _component_v_sheet = _resolveComponent("v-sheet");
            const _component_v_col = _resolveComponent("v-col");
            const _component_v_divider = _resolveComponent("v-divider");
            const _component_v_card_actions = _resolveComponent("v-card-actions");
            const _component_v_card = _resolveComponent("v-card");
            return (_openBlock(), _createBlock(_component_v_card, _mergeProps(_ctx.$attrs, {
                class: [classes.value, "v-card--material pa-3"]
            }), {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        (__props.avatar)
                            ? (_openBlock(), _createBlock(_component_v_avatar, {
                                key: 0,
                                size: "128",
                                class: "mx-auto v-card--material__avatar elevation-6",
                                color: "grey"
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_v_img, { src: __props.avatar }, null, 8, ["src"])
                                ], undefined, true),
                                _: 1
                            }))
                            : (_openBlock(), _createBlock(_component_v_sheet, {
                                key: 1,
                                class: _normalizeClass([{
                                        'py-5': !_ctx.$slots.image,
                                        'px-7': !_ctx.$slots.image,
                                    }, "text-start v-card--material__heading mb-n6"]),
                                color: __props.color,
                                "max-height": __props.icon ? 90 : undefined,
                                width: __props.inline || __props.icon ? 'auto' : '100%',
                                elevation: "6",
                                dark: ""
                            }, {
                                default: _withCtx(() => [
                                    (_ctx.$slots.heading)
                                        ? _renderSlot(_ctx.$slots, "heading", { key: 0 })
                                        : (_ctx.$slots.image)
                                            ? _renderSlot(_ctx.$slots, "image", { key: 1 })
                                            : (__props.title && !__props.icon)
                                                ? (_openBlock(), _createElementBlock("div", {
                                                    key: 2,
                                                    class: "display-1 font-weight-light",
                                                    textContent: _toDisplayString(__props.title)
                                                }, null, 8, _hoisted_2))
                                                : (__props.icon)
                                                    ? (_openBlock(), _createBlock(_component_v_icon, {
                                                        key: 3,
                                                        size: "32",
                                                        icon: __props.icon
                                                    }, null, 8, ["icon"]))
                                                    : _createCommentVNode("", true),
                                    (__props.text)
                                        ? (_openBlock(), _createElementBlock("div", {
                                            key: 4,
                                            class: "headline font-weight-thin",
                                            textContent: _toDisplayString(__props.text)
                                        }, null, 8, _hoisted_3))
                                        : _createCommentVNode("", true)
                                ], undefined, true),
                                _: 3
                            }, 8, ["class", "color", "max-height", "width"])),
                        (_ctx.$slots['after-heading'])
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                _renderSlot(_ctx.$slots, "after-heading")
                            ]))
                            : _createCommentVNode("", true),
                        (__props.hoverReveal)
                            ? (_openBlock(), _createBlock(_component_v_col, {
                                key: 3,
                                cols: "12",
                                class: "text-center py-0 mt-n12"
                            }, {
                                default: _withCtx(() => [
                                    _renderSlot(_ctx.$slots, "reveal-actions")
                                ], undefined, true),
                                _: 3
                            }))
                            : (__props.icon && __props.title)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                    _createElementVNode("div", {
                                        class: "card-title font-weight-light",
                                        textContent: _toDisplayString(__props.title)
                                    }, null, 8, _hoisted_6)
                                ]))
                                : _createCommentVNode("", true)
                    ]),
                    _renderSlot(_ctx.$slots, "default"),
                    (_ctx.$slots.actions)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createVNode(_component_v_divider, { class: "mt-2" }),
                            _createVNode(_component_v_card_actions, { class: "pb-0" }, {
                                default: _withCtx(() => [
                                    _renderSlot(_ctx.$slots, "actions")
                                ], undefined, true),
                                _: 3
                            })
                        ], 64))
                        : _createCommentVNode("", true)
                ], undefined, true),
                _: 3
            }, 16, ["class"]));
        };
    }
});
