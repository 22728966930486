import { createI18n } from "vue-i18n";
import de from "../locales/de.json";
const i18n = createI18n({
    locale: "de",
    fallbackLocale: "en",
    messages: {
        de,
    },
    globalInjection: true,
    legacy: false, // Use Composition API mode
});
export default i18n;
