import { AreaType } from "../../api/types";
import { add, set } from "../helpers";
import { actions } from "./actions";
import { MarketAreaGetters, MarketAreaMutations, MarketAreaState, } from "./types";
export const marketAreaModule = {
    // namespaced: true,
    state: {
        currentMap: "iib-wohnlagenkarte_kd7g",
        activeLayer: "label_house_buy",
        activeArea: AreaType.kgs8,
        previousBounds: null,
        currentBounds: null,
        currentZoom: null,
        previousZoom: null,
        loadAreas: false,
        showDetail: false,
        showTraffic: false,
        showEvalAmount: true,
        additionalVisibleLayers: [],
        additionalVisibleAreas: [],
        detailAreas: [],
        areas: [],
        marketAreaGroups: [],
        initialMarkedArea: null,
        loadingInitialArea: false,
    },
    mutations: {
        [MarketAreaMutations.setCurrentMap]: set(MarketAreaState.currentMap),
        [MarketAreaMutations.setActiveLayer]: set(MarketAreaState.activeLayer),
        [MarketAreaMutations.setActiveArea]: set(MarketAreaState.activeArea),
        [MarketAreaMutations.setPreviousBounds]: set(MarketAreaState.previousBounds),
        [MarketAreaMutations.setPreviousZoom]: set(MarketAreaState.previousZoom),
        [MarketAreaMutations.setCurrentBounds]: set(MarketAreaState.currentBounds),
        [MarketAreaMutations.setCurrentZoom]: set(MarketAreaState.currentZoom),
        [MarketAreaMutations.setLoadAreas]: set(MarketAreaState.loadAreas),
        [MarketAreaMutations.setAreas]: set(MarketAreaState.areas),
        [MarketAreaMutations.setMarketAreaGroups]: set(MarketAreaState.marketAreaGroups),
        [MarketAreaMutations.addMarketAreaGroups]: add(MarketAreaState.marketAreaGroups),
        [MarketAreaMutations.setInitialMarkedArea]: set(MarketAreaState.initialMarkedArea),
        [MarketAreaMutations.setLoadingInitialArea]: set(MarketAreaState.loadingInitialArea),
        [MarketAreaMutations.setShowTraffic]: set(MarketAreaState.showTraffic),
        [MarketAreaMutations.setShowEvalAmount]: set(MarketAreaState.showEvalAmount),
        [MarketAreaMutations.setAdditionalVisibleLayers]: set(MarketAreaState.additionalVisibleLayers),
        [MarketAreaMutations.setAdditionalVisibleAreas]: set(MarketAreaState.additionalVisibleAreas),
        [MarketAreaMutations.addAdditionalVisibleAreas]: set(MarketAreaState.additionalVisibleAreas),
        [MarketAreaMutations.setShowDetail]: set(MarketAreaState.showDetail),
        [MarketAreaMutations.setDetailAreas]: set(MarketAreaState.detailAreas),
    },
    actions: actions,
    getters: {
        [MarketAreaGetters.unselectedAreas]: (state, getters) => {
            const areas = getters[MarketAreaGetters.areasWithGroup];
            return state.areas.filter((a) => !areas.find((b) => b.area.kgs === a.kgs));
        },
        [MarketAreaGetters.areasWithGroup]: (state) => {
            const groupedAreas = state.marketAreaGroups
                .map((val) => {
                return val.selectedAreas.map((area) => {
                    return {
                        area,
                        group: {
                            title: val.title,
                            id: val.id,
                            isDefault: val.isDefault,
                        },
                    };
                });
            })
                .reduce((l, r) => r.concat(l), []);
            const activeType = groupedAreas.filter((groupedArea) => groupedArea.area.areaType === state.activeArea);
            const inactiveType = groupedAreas.filter((groupedArea) => groupedArea.area.areaType !== state.activeArea);
            return [...inactiveType, ...activeType];
        },
        [MarketAreaGetters.marketAreaGroupById]: (state) => (id) => {
            return state.marketAreaGroups.find((g) => g.id === id);
        },
        [MarketAreaGetters.marketAreaGroups]: (state) => state.marketAreaGroups,
        [MarketAreaGetters.loadAreas]: (state) => state.loadAreas,
    },
};
