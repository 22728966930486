import murmurhash from "murmurhash";
import { LngLatBounds } from "maplibre-gl";
import colors from "vuetify/util/colors";
export const set = (property) => (store, payload) => {
    store[property] = payload;
};
export const add = (property) => (store, payload) => store[property].push(payload);
export const timeout = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};
export const latLngBoundsToBBox = (bounds) => {
    return [
        bounds.getNorthWest().lat,
        bounds.getNorthWest().lng,
        bounds.getSouthEast().lat,
        bounds.getSouthEast().lng,
    ];
};
export const bboxToLatLngBounds = (bbox) => {
    return new LngLatBounds([
        [bbox[1], bbox[0]],
        [bbox[3], bbox[2]],
    ]);
};
export const getHashedInt = (text, max) => {
    let hash = murmurhash(text, 0) / 10000000000;
    if (hash < 0.1) {
        hash *= 10;
    }
    return Math.floor(hash * max);
};
export const getRandomMaterialColorFromText = (text) => {
    const colorKeys = getTypedKeys(colors).filter((key) => key !== "shades");
    const color = colors[colorKeys[getHashedInt(text, colorKeys.length)]];
    return color.base;
};
export function getTypedKeys(obj) {
    return Object.keys(obj);
}
export const calculateMarketAreaIndices = (marketArea, groupIdx, areaIdx, kgs) => {
    let tooltip = "";
    if (marketArea.marketAreaGroups.length > 1 && groupIdx > 0) {
        const defaultGroup = marketArea.marketAreaGroups.find((group) => group.isDefault);
        tooltip = `${groupIdx + (defaultGroup?.selectedAreas?.length || 0)}.`;
    }
    tooltip += `${areaIdx + 1}`;
    return { index: tooltip, kgs: kgs };
};
