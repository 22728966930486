import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createTextVNode as _createTextVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useStore } from "vuex";
import { RootActions } from "../../store";
import { RouterLink } from "vue-router";
export default /*@__PURE__*/ _defineComponent({
    __name: 'AppBar',
    setup(__props) {
        const { user, logout } = useAuth0();
        const store = useStore();
        const userEmail = computed(() => user.value?.email || "Unknown User");
        const handleLogout = async () => {
            await store.dispatch(RootActions.clearAll);
            logout({ logoutParams: { returnTo: window.location.origin } });
        };
        return (_ctx, _cache) => {
            const _component_v_img = _resolveComponent("v-img");
            const _component_v_toolbar_title = _resolveComponent("v-toolbar-title");
            const _component_v_spacer = _resolveComponent("v-spacer");
            const _component_v_icon = _resolveComponent("v-icon");
            const _component_v_btn = _resolveComponent("v-btn");
            const _component_v_list_item_title = _resolveComponent("v-list-item-title");
            const _component_v_list_item = _resolveComponent("v-list-item");
            const _component_v_divider = _resolveComponent("v-divider");
            const _component_v_list = _resolveComponent("v-list");
            const _component_v_menu = _resolveComponent("v-menu");
            const _component_v_toolbar_items = _resolveComponent("v-toolbar-items");
            const _component_v_app_bar = _resolveComponent("v-app-bar");
            return (_openBlock(), _createBlock(_component_v_app_bar, {
                color: "white",
                density: "compact",
                "clipped-right": "",
                prominent: ""
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_v_toolbar_title, { class: "pl-0" }, {
                        default: _withCtx(() => [
                            _createVNode(_unref(RouterLink), {
                                to: "/",
                                class: "iib-logo"
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_v_img, {
                                        alt: "IIB Institut",
                                        contain: "",
                                        src: require('@/assets/logo.png'),
                                        width: "128px"
                                    }, null, 8, ["src"])
                                ], undefined, true),
                                _: 1
                            })
                        ], undefined, true),
                        _: 1
                    }),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_toolbar_items, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_v_menu, { "offset-y": "" }, {
                                activator: _withCtx(({ props }) => [
                                    _createVNode(_component_v_btn, _mergeProps(props, {
                                        color: "primary",
                                        icon: ""
                                    }), {
                                        default: _withCtx(() => [
                                            _createVNode(_component_v_icon, null, {
                                                default: _withCtx(() => _cache[0] || (_cache[0] = [
                                                    _createTextVNode("mdi-cog")
                                                ]), undefined, true),
                                                _: 1
                                            })
                                        ], undefined, true),
                                        _: 2
                                    }, 1040)
                                ]),
                                default: _withCtx(() => [
                                    _createVNode(_component_v_list, null, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_v_list_item, null, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_v_list_item_title, null, {
                                                        default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(userEmail.value), 1)
                                                        ], undefined, true),
                                                        _: 1
                                                    })
                                                ], undefined, true),
                                                _: 1
                                            }),
                                            _createVNode(_component_v_divider),
                                            _createVNode(_component_v_list_item, { to: "/" }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_v_list_item_title, null, {
                                                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                                                            _createTextVNode("Zur Übersicht")
                                                        ]), undefined, true),
                                                        _: 1
                                                    })
                                                ], undefined, true),
                                                _: 1
                                            }),
                                            _createVNode(_component_v_divider),
                                            _createVNode(_component_v_list_item, { onClick: handleLogout }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_v_list_item_title, null, {
                                                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                                                            _createTextVNode("Logout")
                                                        ]), undefined, true),
                                                        _: 1
                                                    })
                                                ], undefined, true),
                                                _: 1
                                            })
                                        ], undefined, true),
                                        _: 1
                                    })
                                ], undefined, true),
                                _: 1
                            })
                        ], undefined, true),
                        _: 1
                    })
                ], undefined, true),
                _: 1
            }));
        };
    }
});
