import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "drawer-header price-header" };
const _hoisted_2 = { class: "drawer-header" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { key: 1 };
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import MarketList from "./MarketList.vue";
import MarketPricing from "./MarketPricing.vue";
import SaveMarketAreaDialog from "./SaveMarketAreaDialog.vue";
import Toolbar from "./Toolbar.vue";
import { useRole } from "@/mixins/role";
import { MarketAreaState } from "@/store/marketarea/types";
import { useRoute } from "vue-router";
import AppBar from "../base/AppBar.vue";
import MapView from "../map/MapView.vue";
import { watch } from "vue";
const priceCalculatorWidth = 18;
const reduceWidthAmount = 13;
export default /*@__PURE__*/ _defineComponent({
    __name: 'Layout',
    setup(__props) {
        const store = useStore();
        const initialMarkedArea = computed(() => store.state.marketArea[MarketAreaState.initialMarkedArea]);
        const { isAreaReadOnly, isAdmin } = useRole();
        const route = useRoute();
        const hidePriceCalculator = ref(false);
        const hideSubmarketList = ref(false);
        const toggleMultiple = ref([0]);
        const map = ref(null);
        const showTraffic = computed(() => store.state.marketArea.showTraffic);
        const showEvalAmount = computed(() => store.state.marketArea.showEvalAmount);
        const loadingInitialArea = computed(() => store.state.marketArea.loadingInitialArea);
        const activeArea = computed(() => store.state.marketArea.activeArea);
        const setShowTraffic = (type) => {
            store.commit("setShowTraffic", type);
        };
        const setShowEvalAmount = (type) => {
            store.commit("setShowEvalAmount", type);
        };
        const getInitialMarkedArea = async (payload) => {
            await store.dispatch("getInitialMarkedArea", payload);
        };
        const screenWidth = window.innerWidth || 1440;
        const getSubmarketListExpandedWidth = () => {
            if (screenWidth > 1439) {
                return 630;
            }
            if (screenWidth > 1023) {
                return 550;
            }
            return 480;
        };
        const submarketListWidth = ref(showEvalAmount.value ? getSubmarketListExpandedWidth() : 320);
        watch(showEvalAmount, (showEval) => {
            if (showEval)
                submarketListWidth.value = getSubmarketListExpandedWidth();
            else
                submarketListWidth.value = 320;
        });
        onMounted(async () => {
            if (!isAdmin.value) {
                setShowTraffic(true);
            }
            setShowEvalAmount(true);
            await getInitialMarkedArea({
                id: route.params.id,
                detail: false,
            });
        });
        return (_ctx, _cache) => {
            const _component_v_progress_circular = _resolveComponent("v-progress-circular");
            const _component_v_layout = _resolveComponent("v-layout");
            const _component_v_container = _resolveComponent("v-container");
            const _component_v_btn = _resolveComponent("v-btn");
            const _component_v_list_item_title = _resolveComponent("v-list-item-title");
            const _component_v_divider = _resolveComponent("v-divider");
            const _component_v_col = _resolveComponent("v-col");
            const _component_v_row = _resolveComponent("v-row");
            const _component_v_expand_x_transition = _resolveComponent("v-expand-x-transition");
            const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer");
            const _component_v_tooltip = _resolveComponent("v-tooltip");
            const _component_v_btn_toggle = _resolveComponent("v-btn-toggle");
            const _component_v_main = _resolveComponent("v-main");
            const _component_v_app = _resolveComponent("v-app");
            return (_openBlock(), _createBlock(_component_v_app, { style: { "height": "100vh", "width": "100vw" } }, {
                default: _withCtx(() => [
                    _createVNode(AppBar),
                    (loadingInitialArea.value)
                        ? (_openBlock(), _createBlock(_component_v_container, {
                            key: 0,
                            "fill-height": "",
                            fluid: ""
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_v_layout, {
                                    "align-center": "",
                                    "justify-center": ""
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_v_progress_circular, {
                                            indeterminate: "",
                                            size: 70,
                                            width: 7,
                                            color: "primary"
                                        })
                                    ], undefined, true),
                                    _: 1
                                })
                            ], undefined, true),
                            _: 1
                        }))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createVNode(_component_v_navigation_drawer, {
                                class: "price-nav",
                                location: "right",
                                touchless: "",
                                rail: hidePriceCalculator.value,
                                width: 280,
                                "rail-width": 50,
                                permanent: ""
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_1, [
                                        _createVNode(_component_v_btn, {
                                            class: "expand-btn",
                                            icon: hidePriceCalculator.value ? 'mdi-eye-outline' : 'mdi-eye-off-outline',
                                            location: "left",
                                            variant: "text",
                                            onClick: _cache[0] || (_cache[0] = ($event) => (hidePriceCalculator.value = !hidePriceCalculator.value))
                                        }, null, 8, ["icon"]),
                                        _createVNode(_component_v_list_item_title, null, {
                                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                                                _createTextVNode("Preise")
                                            ]), undefined, true),
                                            _: 1
                                        })
                                    ]),
                                    _createVNode(_component_v_divider),
                                    _createVNode(_component_v_expand_x_transition, { mode: "in-out" }, {
                                        default: _withCtx(() => [
                                            _withDirectives(_createVNode(_component_v_container, {
                                                fluid: "",
                                                class: "px-0 pt-1",
                                                "fill-height": "",
                                                style: { "max-height": "calc(100% - 60px)", "align-items": "inherit" }
                                            }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_v_row, {
                                                        align: "start",
                                                        class: "mt-n4"
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createVNode(_component_v_col, { cols: "12" }, {
                                                                default: _withCtx(() => [
                                                                    _createVNode(MarketPricing)
                                                                ], undefined, true),
                                                                _: 1
                                                            })
                                                        ], undefined, true),
                                                        _: 1
                                                    }),
                                                    (!_unref(isAreaReadOnly)(initialMarkedArea.value))
                                                        ? (_openBlock(), _createBlock(_component_v_row, {
                                                            key: 0,
                                                            justify: "center",
                                                            align: "end"
                                                        }, {
                                                            default: _withCtx(() => [
                                                                _createVNode(_component_v_col, { cols: "auto" }, {
                                                                    default: _withCtx(() => [
                                                                        _createVNode(SaveMarketAreaDialog)
                                                                    ], undefined, true),
                                                                    _: 1
                                                                })
                                                            ], undefined, true),
                                                            _: 1
                                                        }))
                                                        : _createCommentVNode("", true)
                                                ], undefined, true),
                                                _: 1
                                            }, 512), [
                                                [_vShow, !hidePriceCalculator.value]
                                            ])
                                        ], undefined, true),
                                        _: 1
                                    })
                                ], undefined, true),
                                _: 1
                            }, 8, ["rail"]),
                            _createVNode(_component_v_navigation_drawer, {
                                class: "submarket-nav",
                                location: "right",
                                width: submarketListWidth.value,
                                touchless: "",
                                rail: hideSubmarketList.value,
                                "rail-width": 50
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_2, [
                                        _createVNode(_component_v_btn, {
                                            class: "expand-btn",
                                            icon: hideSubmarketList.value ? 'mdi-eye-outline' : 'mdi-eye-off-outline',
                                            location: "left",
                                            variant: "text",
                                            onClick: _cache[1] || (_cache[1] = ($event) => (hideSubmarketList.value = !hideSubmarketList.value))
                                        }, null, 8, ["icon"]),
                                        _createVNode(_component_v_list_item_title, null, {
                                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                                                _createTextVNode("Teilmärkte")
                                            ]), undefined, true),
                                            _: 1
                                        }),
                                        (activeArea.value !== 'plz' && _unref(isAdmin))
                                            ? (_openBlock(), _createBlock(_component_v_btn_toggle, {
                                                key: 0,
                                                modelValue: toggleMultiple.value,
                                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((toggleMultiple).value = $event)),
                                                multiple: "",
                                                density: "compact"
                                            }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_v_tooltip, { location: "top" }, {
                                                        activator: _withCtx(({ props }) => [
                                                            _createVNode(_component_v_btn, _mergeProps(props, {
                                                                icon: showEvalAmount.value ? 'mdi-eye-outline' : 'mdi-eye-off-outline',
                                                                onClick: _cache[2] || (_cache[2] = ($event) => (setShowEvalAmount(!showEvalAmount.value)))
                                                            }, { variant: "text" }), null, 16, ["icon"])
                                                        ]),
                                                        default: _withCtx(() => [
                                                            _createElementVNode("span", null, _toDisplayString(showEvalAmount.value
                                                                ? "Anz. Angebote ausblenden"
                                                                : "Anz. Angebote einblenden"), 1)
                                                        ], undefined, true),
                                                        _: 1
                                                    }),
                                                    _createVNode(_component_v_tooltip, { location: "top" }, {
                                                        activator: _withCtx(({ props }) => [
                                                            _createVNode(_component_v_btn, _mergeProps(props, {
                                                                icon: showTraffic.value
                                                                    ? 'mdi-traffic-light'
                                                                    : 'mdi-traffic-light-outline',
                                                                onClick: _cache[3] || (_cache[3] = ($event) => (setShowTraffic(!showTraffic.value)))
                                                            }, { variant: "text" }), null, 16, ["icon"])
                                                        ]),
                                                        default: _withCtx(() => [
                                                            _createElementVNode("span", null, _toDisplayString(showTraffic.value
                                                                ? "Angebote Ampel ausblenden"
                                                                : "Angebote Ampel einblenden"), 1)
                                                        ], undefined, true),
                                                        _: 1
                                                    })
                                                ], undefined, true),
                                                _: 1
                                            }, 8, ["modelValue"]))
                                            : (activeArea.value !== 'plz' && !_unref(isAdmin))
                                                ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                                    key: 1,
                                                    location: "top"
                                                }, {
                                                    activator: _withCtx(({ props }) => [
                                                        (!showTraffic.value)
                                                            ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 0 }, props, {
                                                                icon: "mdi-eye-outline",
                                                                onClick: _cache[5] || (_cache[5] = ($event) => (setShowTraffic(true)))
                                                            }, { variant: "text" }), null, 16))
                                                            : (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 1 }, props, {
                                                                icon: "mdi-eye-off-outline",
                                                                onClick: _cache[6] || (_cache[6] = ($event) => (setShowTraffic(false)))
                                                            }, { variant: "text" }), null, 16))
                                                    ]),
                                                    default: _withCtx(() => [
                                                        (!showTraffic.value)
                                                            ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Anz. Angebote einblenden "))
                                                            : (_openBlock(), _createElementBlock("span", _hoisted_4, " Anz. Angebote ausblenden "))
                                                    ], undefined, true),
                                                    _: 1
                                                }))
                                                : _createCommentVNode("", true)
                                    ]),
                                    _createVNode(_component_v_divider),
                                    _createVNode(_component_v_expand_x_transition, { mode: "in-out" }, {
                                        default: _withCtx(() => [
                                            _withDirectives(_createVNode(MarketList, null, null, 512), [
                                                [_vShow, !hideSubmarketList.value]
                                            ])
                                        ], undefined, true),
                                        _: 1
                                    })
                                ], undefined, true),
                                _: 1
                            }, 8, ["width", "rail"]),
                            _createVNode(_component_v_main, { style: { "position": "relative" } }, {
                                default: _withCtx(() => [
                                    _createVNode(Toolbar),
                                    _createVNode(_component_v_container, {
                                        fluid: "",
                                        "fill-height": "",
                                        class: "pa-0",
                                        style: { "height": "100%", "position": "relative" }
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(MapView, {
                                                ref_key: "map",
                                                ref: map,
                                                "hide-price-calculator": hidePriceCalculator.value,
                                                "show-eval-amount": showEvalAmount.value,
                                                "hide-submarket-list": hideSubmarketList.value
                                            }, null, 8, ["hide-price-calculator", "show-eval-amount", "hide-submarket-list"])
                                        ], undefined, true),
                                        _: 1
                                    })
                                ], undefined, true),
                                _: 1
                            })
                        ], 64))
                ], undefined, true),
                _: 1
            }));
        };
    }
});
