import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { watch } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { ref } from "vue";
import { RouterView } from "vue-router";
import { useStore } from "vuex";
import { RootMutations } from "./store";
export default /*@__PURE__*/ _defineComponent({
    __name: 'App',
    setup(__props) {
        const { isLoading, getAccessTokenSilently, loginWithRedirect, isAuthenticated, } = useAuth0();
        const store = useStore();
        const token = ref("");
        watch(() => [isLoading.value, isAuthenticated.value], async ([loading, authenticated]) => {
            if (loading)
                return;
            if (authenticated) {
                token.value = await getAccessTokenSilently({});
                store.dispatch(RootMutations.setToken, token.value);
            }
            else {
                loginWithRedirect({ appState: { targetUrl: window.location.origin } });
            }
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(RouterView)));
        };
    }
});
