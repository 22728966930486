import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = ["src"];
const _hoisted_2 = { class: "text-subtitle-2 font-weight-bold primary--text" };
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { calculateMarketAreaIndices } from "../../store/helpers";
import { MarketAreaActions, MarketAreaState, } from "../../store/marketarea/types";
import PrintMap from "./PrintMap.vue";
import AppBar from "../base/AppBar.vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'Layout',
    setup(__props) {
        const store = useStore();
        const route = useRoute();
        const loadingInitialArea = computed(() => store.state.marketArea[MarketAreaState.loadingInitialArea]);
        const initialMarkedArea = computed(() => {
            return store.state.marketArea[MarketAreaState.initialMarkedArea];
        });
        const getInitialMarkedArea = (payload) => {
            return store.dispatch(MarketAreaActions.getInitialMarkedArea, payload);
        };
        const groupIndex = (groupIdx, areaIdx, kgs) => {
            return calculateMarketAreaIndices(initialMarkedArea.value, groupIdx, areaIdx, kgs).index;
        };
        const areaTitle = (group, area) => {
            let title = area.title;
            if (!group.isDefault) {
                title = `${group.title} - ${title}`;
            }
            else if (area.kgs.length > 8) {
                title = `${area.licensePlate} - ${title}`;
            }
            return title;
        };
        onMounted(() => {
            getInitialMarkedArea({ id: route.params.id, detail: true });
        });
        return (_ctx, _cache) => {
            const _component_v_progress_circular = _resolveComponent("v-progress-circular");
            const _component_v_layout = _resolveComponent("v-layout");
            const _component_v_container = _resolveComponent("v-container");
            const _component_v_col = _resolveComponent("v-col");
            const _component_v_row = _resolveComponent("v-row");
            const _component_v_main = _resolveComponent("v-main");
            const _component_v_app = _resolveComponent("v-app");
            return (_openBlock(), _createBlock(_component_v_app, null, {
                default: _withCtx(() => [
                    _createVNode(AppBar, { class: "no-print" }),
                    (loadingInitialArea.value)
                        ? (_openBlock(), _createBlock(_component_v_container, {
                            key: 0,
                            "fill-height": "",
                            fluid: ""
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_v_layout, {
                                    "align-center": "",
                                    "justify-center": ""
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_v_progress_circular, {
                                            indeterminate: "",
                                            size: 70,
                                            width: 7,
                                            color: "primary"
                                        })
                                    ], undefined, true),
                                    _: 1
                                })
                            ], undefined, true),
                            _: 1
                        }))
                        : _createCommentVNode("", true),
                    (!loadingInitialArea.value)
                        ? (_openBlock(), _createBlock(_component_v_main, { key: 1 }, {
                            default: _withCtx(() => [
                                _createVNode(_component_v_container, {
                                    fluid: "",
                                    "fill-height": "",
                                    class: "pa-0",
                                    style: { "position": "relative" }
                                }, {
                                    default: _withCtx(() => [
                                        _cache[0] || (_cache[0] = _createElementVNode("span", {
                                            class: "text-h5 primary--text",
                                            style: { "position": "absolute", "top": "24px", "left": "20px", "z-index": "9999", "vertical-align": "baseline" }
                                        }, " Übersicht Marktgebiet: ", -1)),
                                        _createElementVNode("img", {
                                            alt: "IIB Institut",
                                            style: { "position": "absolute", "top": "12px", "right": "20px", "z-index": "9999", "object-fit": "contain" },
                                            src: require('@/assets/logo.png'),
                                            width: "20%"
                                        }, null, 8, _hoisted_1),
                                        _createVNode(PrintMap, {
                                            "loading-initial-area": loadingInitialArea.value,
                                            ref: "map"
                                        }, null, 8, ["loading-initial-area"])
                                    ], undefined, true),
                                    _: 1
                                }),
                                _createVNode(_component_v_container, {
                                    fluid: "",
                                    "fill-height": "",
                                    style: { "padding-top": "40px", "position": "absolute", "top": "1300px" }
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_v_row, { dense: "" }, {
                                            default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(initialMarkedArea.value?.marketAreaGroups, (group, groupIdx) => {
                                                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.selectedAreas, (area, areaIdx) => {
                                                            return (_openBlock(), _createBlock(_component_v_col, { cols: "3" }, {
                                                                default: _withCtx(() => [
                                                                    _createElementVNode("span", _hoisted_2, _toDisplayString(groupIndex(groupIdx, areaIdx, area.kgs)) + " " + _toDisplayString(areaTitle(group, area)), 1)
                                                                ], undefined, true),
                                                                _: 2
                                                            }, 1024));
                                                        }), 256))
                                                    ], 64));
                                                }), 256))
                                            ], undefined, true),
                                            _: 1
                                        })
                                    ], undefined, true),
                                    _: 1
                                })
                            ], undefined, true),
                            _: 1
                        }))
                        : _createCommentVNode("", true)
                ], undefined, true),
                _: 1
            }));
        };
    }
});
