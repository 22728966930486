export const roundToNearest = (number, nearest) => {
    return (Math.round(number / nearest) * nearest).toFixed(nearest < 1 ? 2 : 0);
};
export const currencyFormatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
});
export const formatNumber = (value, minimumFractionDigits = 0, maximumFractionDigits = 0) => {
    return new Intl.NumberFormat("de-DE", {
        maximumFractionDigits,
        minimumFractionDigits,
    }).format(value);
};
export default {
    install(app) {
        app.config.globalProperties.$filters = {
            formatCurrency(value) {
                return currencyFormatter.format(value);
            },
            formatNumber(value, minimumFractionDigits = 0, maximumFractionDigits = 2) {
                return formatNumber(value, minimumFractionDigits, maximumFractionDigits);
            },
            roundToNearest,
        };
    },
};
